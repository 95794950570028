import { gql } from "apollo-angular";

export const getAllCountries = gql`
  query getAllCountries {
    countries: getAllCountries {
      id
      Name_Country
      PhoneCode_Country
      Iso_Country
    }
  }
`;

export const checkIfAccountIsEnough = gql`
  query checkIfAccountIsEnough($FK_Account: ID, $Total: Float) {
    isEnough: checkIfAccountIsEnough(FK_Account: $FK_Account, Total: $Total)
  }
`;

export const productsFeaturesRandom = gql`
  query productsFeaturesRandom($limit: Int, $offset: Int) {
    featuresRandom: productsFeaturesRandom(limit: $limit, offset: $offset) {
      id
      Details {
        Value_ProductFeatureDetail
        Key_ProductKeyFeature
        FK_ProductKeyFeature
      }
      ProductPrice {
        id
        Discount {
          id
          Quantity
          StartDate
          EndDate
        }
        Price_ProductPrice
        Tax {
          id
          Description_Tax
          MH_Code
        }
        Tariff {
          id
          Name
          Code
          Percent
        }
        Cabys {
          id
          Name
          Code
          Percent
        }
      }
      Product {
        id
        Name_Product
        Description_Product
        InventoryDetail {
          id
          UnitOfMeasurement {
            id
            Name_UnitOfMeasurement
            Symbol
          }
        }
      }
      Files {
        id
        Route_File
        Created_At
        FileType {
          id
        }
      }
    }
  }
`;

export const productsFeaturesByUpdate = gql`
  query productsFeaturesByUpdate($limit: Int, $offset: Int, $placeId: ID!) {
    features: productsFeaturesByUpdate(
      limit: $limit
      offset: $offset
      placeId: $placeId
    ) {
      id
      Quantity_ProductFeature
      Clicks_ProductFeature
      Files {
        id
        Route_File
        Created_At
        FileType {
          id
          Name_FileType
        }
      }
      Details {
        id
        FK_ProductFeature
        Value_ProductFeatureDetail
        Key_ProductKeyFeature
        FK_ProductKeyFeature
      }
      ProductPrice {
        id
        Discount {
          id
          Quantity
          StartDate
          EndDate
        }
        Price_ProductPrice
        Tax {
          id
          Description_Tax
          MH_Code
        }
        Tariff {
          id
          Name
          Code
          Percent
        }
        Cabys {
          id
          Name
          Code
          Percent
        }
      }
      Product {
        id
        Name_Product
        Description_Product
        InventoryDetail {
          id
          UnitOfMeasurement {
            id
            Name_UnitOfMeasurement
            Symbol
          }
        }
        Tags {
          id
          Value
        }
        Inventory {
          id
          Cellar {
            id
            Place {
              id
              Name_Place
              Logo
            }
          }
        }
      }
    }
  }
`;

export const productsFeaturesRelated = gql`
  query productsFeaturesRelated($tags: [Int], $limit: Int, $offset: Int) {
    featuresRelated: productsFeaturesRelated(
      tags: $tags
      limit: $limit
      offset: $offset
    ) {
      id
      Quantity_ProductFeature
      Clicks_ProductFeature
      Files {
        id
        Route_File
        Created_At
        FileType {
          id
          Name_FileType
        }
      }
      Details {
        id
        FK_ProductFeature
        Value_ProductFeatureDetail
        Key_ProductKeyFeature
        FK_ProductKeyFeature
      }
      ProductPrice {
        id
        Price_ProductPrice
        Tax {
          id
          Description_Tax
          MH_Code
        }
        Tariff {
          id
          Name
          Code
          Percent
        }
        Cabys {
          id
          Name
          Code
          Percent
        }
      }
      Product {
        id
        Name_Product
        Description_Product
        InventoryDetail {
          id
          UnitOfMeasurement {
            id
            Name_UnitOfMeasurement
            Symbol
          }
        }
        Tags {
          id
          Value
        }
        Inventory {
          id
          Cellar {
            id
            Place {
              id
              Name_Place
              Logo
            }
          }
        }
      }
    }
  }
`;

export const getBillsByUserStateAndPlace = gql`
  query getBillsByUserStateAndPlace(
    $states: [ID]
    $limit: Int
    $offset: Int
    $placeId: ID!
  ) {
    bills: getBillsByUserStateAndPlace(
      states: $states
      limit: $limit
      offset: $offset
      placeId: $placeId
    ) {
      id
      EB {
        Key
      }
      Details_Bill
      FirebaseId_Bill
      Total_Bill
      SubTotal_Bill
      FreeShipping
      Tax
      Created_At
      Updated_At
      DeliveryMoment
      Delivery {
        id
        DeliveryState {
          id
          Name_DeliveryState
        }
        User {
          id
          NickName_User
          Person {
            id
            Name_Person
            Lastname_Person
            ProfileImage
            Files {
              id
              Route_File
              Created_At
              FileType {
                id
                Name_FileType
              }
            }
          }
        }
      }
      BillState {
        id
        Name_BillState
      }
      Review {
        id
        Rating_BillReview
        Comments_BillReview
        Data
      }
      Address {
        id
        Name_Address
        Description_Address
        Lat_Address
        Lng_Address
      }
      Detail {
        id
        Quantity_BillDetail
        State {
          id
          Name_BillDetailState
        }
        UnitOfMeasurement {
          id
          Name_UnitOfMeasurement
          Symbol
        }
        ProductFeature {
          id
          Quantity_ProductFeature
          PreparationTime_ProductFeature
          Details {
            id
            Key_ProductKeyFeature
            Value_ProductFeatureDetail
          }
          Files {
            id
            Route_File
            Created_At
            FileType {
              id
              Name_FileType
            }
          }
          Product {
            id
            Name_Product
            Description_Product
          }
          ProductPrice {
            id
            Discount {
              id
              Quantity
              StartDate
              EndDate
            }
            Price_ProductPrice
            Tax {
              id
              Description_Tax
              MH_Code
            }
            Tariff {
              id
              Name
              Code
              Percent
            }
            Cabys {
              id
              Name
              Code
              Percent
            }
          }
        }
      }
      BillType {
        id
        Name_BillType
      }
    }
  }
`;

export const progressByBillId = gql`
  query progressByBillId($id: ID) {
    billProgress: progressByBillId(id: $id) {
      id
      Created_At
      BillState {
        id
        Name_BillState
      }
      Delivery {
        id
        Place {
          id
          Name_Place
          Description_Place
          Logo
        }
      }
      Details {
        id
        Quantity_BillDetail
        CheckedByDelivery_BillDetail
        State {
          id
          Name_BillDetailState
        }
        UnitOfMeasurement {
          id
          Name_UnitOfMeasurement
          Symbol
        }
        ProductFeature {
          id
          Quantity_ProductFeature
          PreparationTime_ProductFeature
          Details {
            id
            Key_ProductKeyFeature
            Value_ProductFeatureDetail
          }
          Files {
            id
            Route_File
            Created_At
            FileType {
              id
              Name_FileType
            }
          }
          Product {
            id
            Name_Product
            Description_Product
          }
        }
      }
      NotAvailableDetails {
        id
        Quantity_BillDetail
        CheckedByDelivery_BillDetail
        State {
          id
          Name_BillDetailState
        }
        UnitOfMeasurement {
          id
          Name_UnitOfMeasurement
          Symbol
        }
        ProductFeature {
          id
          Quantity_ProductFeature
          PreparationTime_ProductFeature
          Files {
            id
            Route_File
            Created_At
            FileType {
              id
              Name_FileType
            }
          }
          Product {
            id
            Name_Product
            Description_Product
          }
        }
      }
      User {
        id
        NickName_User
        Person {
          id
          Name_Person
          Lastname_Person
          ProfileImage
        }
      }
    }
  }
`;

export const allCoins = gql`
  query coins {
    coins: getAllCoins {
      id
      Name_Coin
      Symbol_Coin
      Buy_Coin
      Sell_Coin
    }
  }
`;

export const findSearchCriteria = gql`
  query findSearchCriteria(
    $criteria: String
    $limit: Int
    $offset: Int
    $userId: String
    $FK_Module: ID
  ) {
    searchCriteria: findSearchCriteria(
      criteria: $criteria
      limit: $limit
      offset: $offset
      userId: $userId
      FK_Module: $FK_Module
    ) {
      searchCriteriasRelated {
        id
        Criteria
      }
      searchCriteriasRelatedByUser {
        id
        Criteria
        FK_SearchCriteriaUser
      }
    }
  }
`;

export const getTagsForMarketSearch = gql`
  query getTagsForMarketSearch($criteria: String) {
    tags: getTagsForMarketSearch(criteria: $criteria) {
      id
      Value
    }
  }
`;

export const deepInTree = gql`
  query deepInTree($tag: ID!, $limit: Int, $offset: Int, $filters: Filters) {
    result: deepInTree(
      tag: $tag
      limit: $limit
      offset: $offset
      filters: $filters
    ) {
      Features {
        id
        Quantity_ProductFeature
        PreparationTime_ProductFeature
        Files {
          id
          Route_File
          Created_At
          FileType {
            id
            Name_FileType
          }
        }
        Details {
          id
          FK_ProductFeature
          Value_ProductFeatureDetail
          Key_ProductKeyFeature
        }
        ProductPrice {
          id
          Discount {
            id
            Quantity
            StartDate
            EndDate
          }
          Price_ProductPrice
          Tax {
            id
            Description_Tax
            MH_Code
          }
          Tariff {
            id
            Name
            Code
            Percent
          }
          Cabys {
            id
            Name
            Code
            Percent
          }
        }
        Product {
          id
          Name_Product
          Description_Product
          InventoryDetail {
            id
            UnitOfMeasurement {
              id
              Name_UnitOfMeasurement
              Symbol
            }
          }
          Tags {
            id
            Value
          }
          Inventory {
            id
            Cellar {
              id
              Place {
                id
                Name_Place
                Address {
                  id
                  Lat_Address
                  Lng_Address
                }
              }
            }
          }
        }
      }
      SonTags {
        id
        Value
        TagCategory {
          id
          Category
        }
      }
      FatherTags {
        id
        Value
        TagCategory {
          id
          Category
        }
      }
      FeatureTags {
        id
        Value
      }
    }
  }
`;

export const deepInTreebyTags = gql`
  query deepInTreebyTags(
    $tags: [ID]
    $limit: Int
    $offset: Int
    $filters: Filters
  ) {
    result: deepInTreebyTags(
      tags: $tags
      limit: $limit
      offset: $offset
      filters: $filters
    ) {
      Features {
        id
        Quantity_ProductFeature
        PreparationTime_ProductFeature
        Files {
          id
          Route_File
          Created_At
          FileType {
            id
            Name_FileType
          }
        }
        Details {
          id
          FK_ProductFeature
          Value_ProductFeatureDetail
          Key_ProductKeyFeature
        }
        ProductPrice {
          id
          Discount {
            id
            Quantity
            StartDate
            EndDate
          }
          Price_ProductPrice
          Tax {
            id
            Description_Tax
            MH_Code
          }
          Tariff {
            id
            Name
            Code
            Percent
          }
          Cabys {
            id
            Name
            Code
            Percent
          }
        }
        Product {
          id
          Name_Product
          Description_Product
          InventoryDetail {
            id
            UnitOfMeasurement {
              id
              Name_UnitOfMeasurement
              Symbol
            }
          }
          Tags {
            id
            Value
          }
          Inventory {
            id
            Cellar {
              id
              Place {
                id
                Name_Place
                Address {
                  id
                  Lat_Address
                  Lng_Address
                }
              }
            }
          }
        }
      }
      SonTags {
        id
        Value
        TagCategory {
          id
          Category
        }
      }
      FatherTags {
        id
        Value
        TagCategory {
          id
          Category
        }
      }
      FeatureTags {
        id
        Value
      }
    }
  }
`;

export const findProductFeaturesByCriteriaPublic = gql`
  query findProductFeaturesByCriteriaPublic(
    $criteria: String
    $limit: Int
    $offset: Int
    $filters: Filters
    $placeId: Int!
  ) {
    Features: findProductFeaturesByCriteriaPublic(
      criteria: $criteria
      limit: $limit
      offset: $offset
      filters: $filters
      placeId: $placeId
    ) {
      id
      Quantity_ProductFeature
      PreparationTime_ProductFeature
      Files {
        id
        Route_File
        Created_At
        FileType {
          id
          Name_FileType
        }
      }
      Details {
        id
        FK_ProductFeature
        Value_ProductFeatureDetail
        Key_ProductKeyFeature
        FK_ProductKeyFeature
      }
      ProductPrice {
        id
        Discount {
          id
          Quantity
          StartDate
          EndDate
        }
        Price_ProductPrice
        Tax {
          id
          Description_Tax
          MH_Code
        }
        Tariff {
          id
          Name
          Code
          Percent
        }
        Cabys {
          id
          Name
          Code
          Percent
        }
      }
      Product {
        id
        Name_Product
        Description_Product
        InventoryDetail {
          id
          UnitOfMeasurement {
            id
            Name_UnitOfMeasurement
            Symbol
          }
        }
        Tags {
          id
          Value
        }
        Inventory {
          id
          Cellar {
            id
            Place {
              id
              Name_Place
              Logo
            }
          }
        }
      }
    }
  }
`;

export const searchTags = gql`
  query searchTag($criteria: String) {
    tags: searchTag(value: $criteria) {
      id
      Value
      TagCategory {
        id
        Category
      }
    }
  }
`;

export const sonTags = gql`
  query sonTags($id: ID!) {
    tags: sonTags(id: $id) {
      id
      Value
      TagCategory {
        id
        Category
      }
    }
  }
`;

export const isBillAvailable = gql`
  query isBillAvailable($id: ID) {
    details: isBillAvailable(id: $id) {
      id
      Quantity_BillDetail
      ProductFeature {
        id
        Quantity_ProductFeature
      }
    }
  }
`;

export const myCart = gql`
  query myCart($placeId: Int!) {
    myCart(placeId: $placeId) {
      id
      Place {
        id
        Name_Place
      }
      NotAvailableAddressToSend {
        id
        Lat
        Lng
        Distance
        Amount
        Tax
        Services
        Delivery
        DeliveryTax
        Time
        User {
          id
        }
        Address {
          id
        }
        Approved
      }
      Address {
        id
        Name_Address
        Description_Address
        Lat_Address
        Lng_Address
      }
      Detail {
        id
        Quantity_BillDetail
        SubTotal_BillDetail
        Total_BillDetail
        State {
          id
          Name_BillDetailState
        }
        UnitOfMeasurement {
          id
          Name_UnitOfMeasurement
          Symbol
        }
        ProductFeature {
          id
          Quantity_ProductFeature
          PreparationTime_ProductFeature
          Details {
            id
            Key_ProductKeyFeature
            Value_ProductFeatureDetail
          }
          Files {
            id
            Route_File
            Created_At
            FileType {
              id
              Name_FileType
            }
          }
          Product {
            id
            Name_Product
            Description_Product
            Tags {
              id
              Value
            }
            Inventory {
              id
              Cellar {
                id
                Place {
                  id
                  Name_Place
                  Logo
                  Address {
                    id
                    Lat_Address
                    Lng_Address
                  }
                  Schedules {
                    id
                    OpenHour_Schedule
                    CloseHour_Schedule
                    Detail {
                      id
                      Day {
                        id
                        Name_Day
                        Value_Day
                      }
                    }
                  }
                }
              }
            }
          }
          ProductPrice {
            id
            Discount {
              id
              Quantity
              StartDate
              EndDate
            }
            Price_ProductPrice
            Tax {
              id
              Description_Tax
              MH_Code
            }
            Tariff {
              id
              Name
              Code
              Percent
            }
            Cabys {
              id
              Name
              Code
              Percent
            }
          }
        }
      }
      BillType {
        id
        Name_BillType
      }
    }
  }
`;

export const getMHUserByFK_Person = gql`
  query getMHUserByFK_Person($id: ID!) {
    userEB: getMHUserByFK_Person(id: $id) {
      idUser
      fullName
      userName
      email
      pwd
      about
      country
      Logged
      FileEB {
        idFile
        md5
        name
        timestamp
        size
        idUser
        fileType
        type
      }
      Session {
        idSession
        idUser
        sessionKey
        ip
        lastAccess
      }
      MH_User
      MH_Password
    }
  }
`;

export const featureById = gql`
  query featureById($id: ID!) {
    feature: featureById(id: $id) {
      id
      Quantity_ProductFeature
      Clicks_ProductFeature
      Files {
        id
        Route_File
        Created_At
        FileType {
          id
          Name_FileType
        }
      }
      Details {
        id
        FK_ProductFeature
        Value_ProductFeatureDetail
        Key_ProductKeyFeature
        FK_ProductKeyFeature
      }
      ProductPrice {
        id
        Discount {
          id
          Quantity
          StartDate
          EndDate
        }
        Price_ProductPrice
        Tax {
          id
          Description_Tax
          MH_Code
        }
        Tariff {
          id
          Name
          Code
          Percent
        }
        Cabys {
          id
          Name
          Code
          Percent
        }
      }
      Product {
        id
        Name_Product
        Description_Product
        InventoryDetail {
          id
          UnitOfMeasurement {
            id
            Name_UnitOfMeasurement
            Symbol
          }
        }
        Tags {
          id
          Value
        }
        Inventory {
          id
          Cellar {
            id
            Place {
              id
              Name_Place
              Logo
            }
          }
        }
      }
    }
  }
`;

export const muyTicoCategories = gql`
  query muyticoTags {
    muyticoTags {
      id
      Value
      FeatureTags {
        id
        Value
      }
    }
  }
`;

export const getCuponsByGoogleAuth = gql`
  query getCuponsByGoogleAuth {
    cupons: getCuponsByGoogleAuth {
      id
      Count
      ExpiredAt
      Cupon {
        id
        Value
        Type {
          id
          Name
          Icon
        }
      }
    }
  }
`;

export const validatePhone = gql`
  query validatePhone($phone: String, $codeRegion: String) {
    validatePhone: validatePhone(phone: $phone, codeRegion: $codeRegion) {
      invalidCountryPhone
    }
  }
`;

export const ninja = gql`
  query ninja($uid: ID!) {
    ninja(uid: $uid)
  }
`;

export const billToPay = gql`
  query billToPay($id: ID!) {
    billToPay(id: $id) {
      id
      NotAvailableAddressToSend {
        id
        Lat
        Lng
        Distance
        Amount
        Tax
        Services
        Delivery
        DeliveryTax
        Time
        User {
          id
        }
        Address {
          id
        }
        Approved
      }
      Address {
        id
        Name_Address
        Description_Address
        Lat_Address
        Lng_Address
      }
      Detail {
        id
        Quantity_BillDetail
        SubTotal_BillDetail
        Total_BillDetail
        State {
          id
          Name_BillDetailState
        }
        UnitOfMeasurement {
          id
          Name_UnitOfMeasurement
          Symbol
        }
        ProductFeature {
          id
          Quantity_ProductFeature
          PreparationTime_ProductFeature
          Details {
            id
            Key_ProductKeyFeature
            Value_ProductFeatureDetail
          }
          Files {
            id
            Route_File
            Created_At
            FileType {
              id
              Name_FileType
            }
          }
          Product {
            id
            Name_Product
            Description_Product
            Tags {
              id
              Value
            }
            Inventory {
              id
              Cellar {
                id
                Place {
                  id
                  Name_Place
                  Logo
                  Address {
                    id
                    Lat_Address
                    Lng_Address
                  }
                  Schedules {
                    id
                    OpenHour_Schedule
                    CloseHour_Schedule
                    Detail {
                      id
                      Day {
                        id
                        Name_Day
                        Value_Day
                      }
                    }
                  }
                }
              }
            }
          }
          ProductPrice {
            id
            Discount {
              id
              Quantity
              StartDate
              EndDate
            }
            Price_ProductPrice
            Tax {
              id
              Description_Tax
              MH_Code
            }
            Tariff {
              id
              Name
              Code
              Percent
            }
            Cabys {
              id
              Name
              Code
              Percent
            }
          }
        }
      }
      BillType {
        id
        Name_BillType
      }
    }
  }
`;

export const cuponByid = gql`
  query cuponByid {
    cupon: cuponByid {
      id
      Count
      ExpiredAt
      Cupon {
        id
        Value
        Type {
          id
          Name
          Icon
        }
      }
    }
  }
`;

export const getPlaceById = gql`
  query publicPlace($id: ID!) {
    place: publicPlace(id: $id) {
      id
      Name_Place
      Description_Place
      Address {
        id
        Lat_Address
        Lng_Address
      }
      Schedules {
        id
        OpenHour_Schedule
        CloseHour_Schedule
        Detail {
          id
          Day {
            id
            Name_Day
            Value_Day
          }
        }
      }
    }
  }
`;

export const downloadXML = gql`
  query downloadXML($id: String!) {
    downloadXML(id: $id)
  }
`;

export const downloadRespXML = gql`
  query downloadRespXML($id: String!) {
    downloadRespXML(id: $id)
  }
`;
