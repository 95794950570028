import { ApiService } from '../services/api.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirePerformance } from '@angular/fire/compat/performance';
import { State, Selector } from '@ngxs/store';
import { Places } from '../interface/place';
import { Injectable } from '@angular/core';
@State<Places>({
  name: 'places',
  defaults: {}
})
@Injectable()
export class PlacesState {
  @Selector()
  static placesWithShop({ placesWithShop }: Places) {
    return placesWithShop;
  }

  constructor(private api: ApiService, public afAuth: AngularFireAuth, private afp: AngularFirePerformance) { }
}
