import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { PaymentState } from 'src/app/states/payment.state';

@Pipe({
  name: 'paymentConversion'
})
export class PaymentConversionPipe implements PipeTransform {

  constructor(private store?: Store) {}

  transform(value: number): Observable<string> {
    return this.store.select(PaymentState.coins).pipe(
      switchMap( coins => {
        if (coins && coins.length > 0) {
          const newValue = value / coins[0].Buy_Coin;
          return of (`${coins[0].Symbol_Coin}${newValue.toLocaleString('en-US', {maximumFractionDigits: 2})}`);
        }
        return of(`$${value.toLocaleString('en-US', {maximumFractionDigits: 2})}`);
      })
    );
  }

}
