import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { distance } from '../helpers/distance';

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  constructor() { }

  getDistance = (origin, points) => {
    const results = [];
    return new Observable(observer => {
      points.forEach(point => {
        const { lat, lng } = point;
        const to = [Number(lng), Number(lat)];
        const from = [origin.lng, origin.lat]
        const kilometers = distance(from, to, { units: 'kilometers' });
        results.push({
          id: point.id ? point.id : null,
          distance: kilometers
        });
      });
      observer.next(results);
    });
  }

}
