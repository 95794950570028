
export class CheckBrowser {
  static readonly type = '[Device] CheckBrowser]';
}

export class RequestPermission {
  static readonly type = '[Device] RequestPermission]';
  constructor(public readonly bill?: string) { }
}

export class WebMessaging {
  static readonly type = '[Device] WebMessaging]';
  constructor(public readonly bill?: string) { }
}

export class NativeMessaging {
  static readonly type = '[Device] NativeMessaging]';
  constructor(public readonly bill?: string) { }
}

export class SaveDeviceToken {
  static readonly type = '[Device] SaveDeviceToken]';
  constructor(public readonly token: string, public readonly bill?: string) { }
}

export class SubscribeToPlace {
  static readonly type = '[Device] SubscribeToPlace]';
  constructor(public readonly token: string, public readonly FK_Place: string) { }
}

export class SusbcribeToBill {
  static readonly type = '[Device] SusbcribeToBill]';
  constructor(public readonly token: string, public readonly bill: string) { }
}

export class SuscribeToNotification {
  static readonly type = '[FCM] SuscribeToNotification]';
  constructor(public readonly Token_NotificationDevice: string) { }
}
