import { gql } from "apollo-angular";

export const userByUID = gql`
  query userByUID($uid: ID) {
    firebaseUser: userByUID(uid: $uid) {
      id
      Email
      Name
      Given_Name
      AcceptTerms
      ShowGuide
      Family_Name
      Picture
      Locale
      Verified_Email
      UID
      Phone_Number
      Provider_Id
      TavuelUser {
        id
        NickName_User
        UserState {
          id
          Name_UserState
        }
        NotificationDevices {
          id
          uuid
          Token_NotificationDevice
          State_NotificationDevice
        }
        Sudo
        DeliveryRole {
          id
          Name_Role
        }
        FollowedPlaces {
          id
          Name_Place
          Description_Place
        }
        Person {
          id
          Name_Person
          Lastname_Person
          Email
          Phone
          ProfileImage
          DocumentId_Person
          Files {
            id
            Route_File
            Created_At
            FileType {
              id
              Name_FileType
            }
          }
          Addresses {
            id
            Name_Address
            Description_Address
            Lat_Address
            Lng_Address
          }
        }
        DefaultCoin {
          id
          Name_Coin
          Symbol_Coin
          Sell_Coin
          Buy_Coin
        }
      }
    }
  }
`;

export const registerUser = gql`
  mutation registerUser($user: GoogleAuthInput) {
    registerUser: registerUser(user: $user) {
      id
      Token
      Email
      Name
      Given_Name
      AcceptTerms
      ShowGuide
      Family_Name
      Picture
      Locale
      Verified_Email
      Refresh_Token
      Created_At
      Updated_At
    }
  }
`;
export const registerClient = gql`
  mutation registerClient($user: GoogleAuthInput!, $placeId: Int!) {
    registerClient: registerClient(user: $user, placeId: $placeId) {
      id
      Token
      Email
      Name
      Given_Name
      AcceptTerms
      ShowGuide
      Family_Name
      Picture
      Locale
      Verified_Email
      Refresh_Token
      Created_At
      Updated_At
    }
  }
`;

export const registerPerson = gql`
  mutation registerPerson($user: GoogleAuthInput) {
    registerPerson: registerPerson(user: $user) {
      id
      Email
      Name
      Given_Name
      AcceptTerms
      ShowGuide
      Family_Name
      Picture
      Locale
      Verified_Email
      UID
      Phone_Number
      Provider_Id
      TavuelUser {
        id
        NickName_User
        DefaultCoin {
          id
          Name_Coin
          Symbol_Coin
          Sell_Coin
          Buy_Coin
        }
        Person {
          id
          Name_Person
          Lastname_Person
          Email
          Phone
          ProfileImage
          Country {
            id
            Name_Country
          }
          Files {
            id
            Route_File
            Created_At
            FileType {
              id
              Name_FileType
            }
          }
          Addresses {
            id
            Name_Address
            Description_Address
            Lat_Address
            Lng_Address
          }
        }
      }
    }
  }
`;

export const firebaseAuth = gql`
  mutation firebaseAuth($user: GoogleAuthInput) {
    firebaseUser: firebaseAuth(user: $user) {
      id
      UID
      Token
      Email
      Name
      Given_Name
      AcceptTerms
      ShowGuide
      Family_Name
      Picture
      Locale
      Verified_Email
      Refresh_Token
      Created_At
      Updated_At
    }
  }
`;

export const createProductReview = gql`
  mutation createProductReview($review: ProductReviewInput) {
    newReview: createProductReview(review: $review) {
      id
      Rating_ProductFeatureReview
    }
  }
`;

export const createBillReview = gql`
  mutation createBillReview($review: BillReviewInput) {
    newReview: createBillReview(review: $review) {
      id
      Rating_BillReview
      Comments_BillReview
      Data
    }
  }
`;

export const updateAddress = gql`
  mutation updateAddress($id: ID, $address: AddressInput) {
    updatedAddress: updateAddress(id: $id, address: $address) {
      id
      Name_Address
      Description_Address
      Lat_Address
      Lng_Address
    }
  }
`;

export const updateAddressState = gql`
  mutation updateAddressState($id: ID, $State_Address: Int) {
    address: updateAddressState(id: $id, State_Address: $State_Address) {
      id
    }
  }
`;

export const createAddress = gql`
  mutation createAddress($address: AddressInput) {
    addressResult: createAddress(address: $address) {
      id
      Name_Address
      Description_Address
      Lat_Address
      Lng_Address
    }
  }
`;

export const disableShowGuide = gql`
  mutation disableShowGuide {
    result: disableShowGuide
  }
`;

export const sendCoinsToWallet = gql`
  mutation isuxumqppu(
    $srlorytuak: String
    $ktndxlufpy: String
    $whwjwtyypj: String
  ) {
    transaction: isuxumqppu(
      srlorytuak: $srlorytuak
      ktndxlufpy: $ktndxlufpy
      whwjwtyypj: $whwjwtyypj
    )
  }
`;

export const updateUserProfilePicture = gql`
  mutation updateUserProfilePicture($Route_File: String, $FirebaseId: String) {
    updated: updateUserProfilePicture(
      Route_File: $Route_File
      FirebaseId: $FirebaseId
    )
  }
`;

export const createBillDetail = gql`
  mutation createBillDetail($detail: BillDetailInput) {
    billDetail: createBillDetail(detail: $detail) {
      id
      Quantity_BillDetail
      SubTotal_BillDetail
      Total_BillDetail
      UnitOfMeasurement {
        Name_UnitOfMeasurement
        Symbol
      }
      ProductFeature {
        id
        Quantity_ProductFeature
        PreparationTime_ProductFeature
        Details {
          Key_ProductKeyFeature
          Value_ProductFeatureDetail
        }
        Files {
          id
          Route_File
          Created_At
          FileType {
            id
            Name_FileType
          }
        }
        Product {
          id
          Name_Product
          Description_Product
          Tags {
            id
          }
          Inventory {
            id
            Cellar {
              id
              Place {
                id
                Name_Place
                Address {
                  id
                  Lat_Address
                  Lng_Address
                }
                Logo
              }
            }
          }
        }
        ProductPrice {
          id
          Price_ProductPrice
          Tax {
            id
            Description_Tax
            MH_Code
          }
          Tariff {
            id
            Name
            Code
            Percent
            Created_At
            Updated_At
          }
          Cabys {
            id
            Name
            Code
            Percent
          }
          Discount {
            id
            Quantity
            StartDate
            EndDate
          }
        }
      }
    }
  }
`;

export const updateBillDetailQuantity = gql`
  mutation updateBillDetailQuantity($id: ID, $quantity: Float, $total: Float!) {
    billDetail: updateBillDetailQuantity(
      id: $id
      quantity: $quantity
      total: $total
    ) {
      id
      Quantity_BillDetail
      SubTotal_BillDetail
      Total_BillDetail
      ProductFeature {
        id
        Quantity_ProductFeature
      }
    }
  }
`;

export const removeBillDetail = gql`
  mutation deleteBillDetail($id: ID!) {
    billDetail: deleteBillDetail(id: $id)
  }
`;

export const createSearchCriteria = gql`
  mutation createSearchCriteria($criteria: String) {
    created: createSearchCriteria(criteria: $criteria, FK_Module: 3)
  }
`;

export const updateUserPhone = gql`
  mutation updateUserPhone($newPhone: String!) {
    edited: updateUserPhone(newPhone: $newPhone)
  }
`;

export const editDetailsBill = gql`
  mutation editDetailsBill($newDetails: String!, $billId: String!) {
    edited: editDetailsBill(newDetails: $newDetails, billId: $billId)
  }
`;

export const deleteSearchCriteriaUser = gql`
  mutation deleteSearchCriteriaById($id: ID!) {
    deleted: deleteSearchCriteriaById(id: $id)
  }
`;

export const payCart = gql`
  mutation payCart($cart: PayingCartInput) {
    payCart(cart: $cart) {
      clave
      xmlFirmado
    }
  }
`;

export const selectBillAddress = gql`
  mutation selectBillAddress($billId: ID!, $addressId: ID!) {
    selected: selectBillAddress(billId: $billId, addressId: $addressId)
  }
`;

export const suscribeToNotification = gql`
  mutation suscribeToNotification(
    $notificationDevice: NotificationDeviceInput
  ) {
    notificationDevice: suscribeToNotification(
      notificationDevice: $notificationDevice
    ) {
      id
      uuid
      Token_NotificationDevice
      State_NotificationDevice
    }
  }
`;

export const addClickToProductFeature = gql`
  mutation addClickToProductFeature($id: ID) {
    updated: addClickToProductFeature(id: $id)
  }
`;

export const createNotAvailableAddressToSend = gql`
  mutation createNotAvailableAddressToSend(
    $notAvailable: NotAvailableAddressToSendInput
  ) {
    inserted: createNotAvailableAddressToSend(notAvailable: $notAvailable) {
      id
      Lat
      Lng
      Distance
      Amount
      Tax
      Services
      Delivery
      DeliveryTax
      Time
      User {
        id
      }
      Address {
        id
      }
      Approved
    }
  }
`;

export const subtractCupon = gql`
  mutation subtractCupon($cuponId: String!) {
    valid: subtractCupon(cuponId: $cuponId)
  }
`;

export const payCartNative = gql`
  mutation payCartNative($cart: PayingCartInput) {
    payCartNative(cart: $cart) {
      clave
      xmlFirmado
    }
  }
`;

export const suscribeToBill = gql`
  mutation suscribeToBill($notificationDevice: NotificationDeviceInput) {
    notificationDevice: suscribeToBill(
      notificationDevice: $notificationDevice
    ) {
      id
      uuid
      Token_NotificationDevice
      State_NotificationDevice
    }
  }
`;

export const updateDevice = gql`
  mutation updateDevice($device: DeviceInput) {
    device: updateDevice(device: $device) {
      id
      uuid
      Name
      Model
      AppId
      AppVersion
      AppBuild
      OperatingSystem
      OsVersion
      Manufacturer
      Platform
      Token
      Created_At
      Updated_At
      Notification {
        id
        Token_NotificationDevice
        State_NotificationDevice
      }
    }
  }
`;

export const saveDeviceToken = gql`
  mutation saveDeviceToken($token: String!) {
    saveDeviceToken(token: $token)
  }
`;

export const subscribeToPlace = gql`
  mutation subscribeToPlace($token: String!, $FK_Place: ID!) {
    subscribeToPlace(token: $token, FK_Place: $FK_Place)
  }
`;
