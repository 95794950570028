import { Injectable } from "@angular/core";
import { Action, NgxsOnInit, Selector, State, StateContext } from "@ngxs/store";
import { patch } from "@ngxs/store/operators";
import { tap } from "rxjs/operators";
import { Payment } from "src/app/interface/payment";
import { ApiService } from "../services/api.service";
import {
  GetCartPay,
  PayCartNative,
  PayCoins,
} from "./actions/payment.actions";

@State<Payment>({
  name: "payment",
  defaults: {},
})
@Injectable()
export class PaymentState implements NgxsOnInit {
  ngxsOnInit({ dispatch }: StateContext<Payment>) {
    dispatch(new PayCoins());
  }
  constructor(private api: ApiService) {}

  @Selector()
  static billToPay({ billToPay }: Payment) {
    return billToPay;
  }

  @Selector()
  static coins({ coins }: Payment) {
    return coins;
  }

  @Action(GetCartPay)
  getCartPay({ setState }: StateContext<Payment>, id: GetCartPay) {
    return this.api.billToPay(id).pipe(
      tap(({ data: { billToPay } }) => {
        setState(patch({ billToPay }));
      })
    );
  }

  @Action(PayCartNative)
  payCartNative({}: StateContext<Payment>, { cart }: PayCartNative) {
    return this.api.payCartNative(cart).pipe(
      tap(({ data }) => {
        if (
          window["webkit"] &&
          window["webkit"].messageHandlers["cordova_iab"]
        ) {
          window["webkit"].messageHandlers["cordova_iab"].postMessage(
            JSON.stringify({ complete: true, data })
          );
        } else {
          window.close();
        }
      })
    );
  }

  @Action(PayCoins)
  payCoins({ setState }: StateContext<Payment>) {
    return this.api.allCoins().pipe(
      tap(({ data: { coins } }) => {
        if (coins) {
          setState(patch({ coins }));
        }
      })
    );
  }
}
