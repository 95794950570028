import {
  State,
  Action,
  StateContext,
  Selector,
  NgxsOnInit,
  Store,
} from "@ngxs/store";
import { UI } from "../interface/ui";
import { Platform } from "@angular/cdk/platform";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SwUpdate } from "@angular/service-worker";
import {
  VerifyInstallPrompt,
  CheckUpdated,
  SetDrawer,
  InstallApp,
  ShareContent,
  Vibrate,
  SetProgress,
  SetLoading,
  SetMobile,
  GetCarrousel,
  CheckBrowser,
  NativeApp,
  DownloadXML,
  DownloadRespXML,
} from "./actions/ui.actions";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { Injectable, NgZone } from "@angular/core";
import { tap } from "rxjs/operators";
import { patch } from "@ngxs/store/operators";
import { Plugins } from "@capacitor/core";
import { UserState } from "./user.state";
import { ApiService } from "../services/api.service";
import { saveAs } from "file-saver";

const { Device, Share, Clipboard, StatusBar } = Plugins;

let win: any;

@State<UI>({
  name: "ui",
  defaults: {
    loadingData: {
      loading: false,
      yProgress: 0,
    },
    mobile: false,
  },
})
@Injectable()
export class UIState implements NgxsOnInit {
  window: any;

  @Selector()
  static device({ device }: UI) {
    return device;
  }

  @Selector()
  static native({ native }: UI) {
    return native;
  }

  @Selector()
  static ios({ device }: UI) {
    if (device) {
      return device.IOS;
    }
  }

  @Selector()
  static android({ device }: UI) {
    if (device) {
      return device.ANDROID;
    }
  }

  @Selector()
  static instagram({ device }: UI) {
    if (device) {
      return device.userAgent.includes("Instagram");
    }
  }

  @Selector()
  static facebook({ device }: UI) {
    if (device) {
      return (
        device.userAgent.indexOf("FBAN") > -1 ||
        device.userAgent.indexOf("FBAV") > -1
      );
    }
  }

  @Selector()
  static googleAuthSupport({
    device,
    native,
    deviceInfo: { operatingSystem },
  }: UI) {
    if (device) {
      const support =
        !(
          device.userAgent.includes("FBAN") ||
          device.userAgent.includes("FBAV") ||
          device.userAgent.includes("Instagram")
        ) &&
        !native &&
        operatingSystem !== "ios" &&
        operatingSystem !== "android";
      return support;
    }
    return false;
  }

  @Selector()
  static selectedColor({ selectedColor }: UI) {
    return selectedColor;
  }

  @Selector()
  static install({ install }: UI) {
    return install;
  }

  @Selector()
  static loadingData({ loadingData }: UI) {
    return loadingData;
  }

  @Selector()
  static mobile({ mobile }: UI) {
    return mobile;
  }

  @Selector()
  static loading({ loadingData: { loading } }: UI) {
    return loading;
  }

  @Selector()
  static muyticoCarouselTop({
    muyticoCarousel: { top },
    deviceInfo: { platform },
  }: UI) {
    return top;
  }

  @Selector()
  static uuid({ deviceInfo: { uuid } }: UI) {
    return uuid;
  }

  @Selector()
  static iosApp({ deviceInfo: { platform } }: UI) {
    return platform && platform === "ios";
  }

  @Selector()
  static nativeApp({ deviceInfo: { platform } }: UI) {
    return (platform && platform === "ios") || platform === "android";
  }

  @Selector()
  static mac({ deviceInfo: { operatingSystem } }: UI) {
    return operatingSystem === "ios" || operatingSystem === "android";
  }

  @Selector()
  static muyticoCarouselBottom({ muyticoCarousel: { bottom }, install }: UI) {
    if (!bottom) {
      return null;
    }
    if (!install) {
      const newFiles = bottom.filter((file) =>
        file.click ? file.click.action !== "InstallApp" : true
      );
      return newFiles;
    } else {
      return bottom;
    }
  }

  @Selector()
  static webApp({ deviceInfo: { platform } }: UI) {
    return platform && platform === "web";
  }

  @Selector()
  static notificationSupport({
    deviceInfo: { platform, operatingSystem },
  }: UI) {
    if (platform === "web" && operatingSystem === "ios") {
      return false;
    }
    return true;
  }

  constructor(
    public platform: Platform,
    private snackBar: MatSnackBar,
    private swUpdate: SwUpdate,
    private fs: AngularFirestore,
    private zone: NgZone,
    private store: Store,
    private api: ApiService
  ) {}

  async ngxsOnInit({ dispatch, patchState }: StateContext<UI>) {
    dispatch(new VerifyInstallPrompt());
    dispatch(new CheckUpdated());
    dispatch(new GetCarrousel());
    dispatch(new CheckBrowser());
  }

  @Action(VerifyInstallPrompt)
  verifyInstallPrompt({ patchState }: StateContext<UI>) {
    window.addEventListener("beforeinstallprompt", (install: any) => {
      install.preventDefault();
      patchState({ install });
    });
  }

  @Action(CheckBrowser)
  async CheckBrowser({ setState }: StateContext<UI>) {
    win = window.navigator;
    let deviceInfo = await Device.getInfo();

    if (
      deviceInfo.platform === "ios" ||
      deviceInfo.platform === "android" ||
      deviceInfo.platform !== "web"
    ) {
      StatusBar.hide();
    }
    const { platform } = navigator;
    const userAgent = navigator.userAgent || navigator.vendor || win.opera;
    setState(
      patch({
        device: { ...this.platform, userAgent, name: platform },
        deviceInfo,
      })
    );
  }

  @Action(CheckUpdated)
  checkUpdated({}: StateContext<UI>) {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {
        this.openSnackBar(
          "Nueva versión disponible  😄 ",
          "OK",
          {
            panelClass: ["custom-snackbar"],
          },
          () => {
            window.location.reload();
          }
        );
      });
    }
  }

  @Action(SetProgress)
  SetProgress(
    { patchState, getState }: StateContext<UI>,
    { yProgress }: SetProgress
  ) {
    const { loadingData } = getState();
    patchState({ loadingData: { ...loadingData, yProgress } });
  }

  @Action(SetDrawer)
  SetDrawer({ patchState }: StateContext<UI>, { drawer }: SetDrawer) {
    patchState({ sidenav: drawer });
  }
  @Action(SetMobile)
  setMobile({ patchState }: StateContext<UI>, { mobile }: SetMobile) {
    patchState({ mobile });
  }

  @Action(InstallApp)
  async installApp({ getState, patchState }: StateContext<UI>) {
    const { install } = getState();
    if (install !== undefined) {
      install.prompt();
      const choiceResult = await install.userChoice;
      if (choiceResult.outcome === "dismissed") {
        console.log("User cancelled home screen install");
      } else {
        console.log("User added to home screen");
      }
      patchState({ install: null });
    }
  }

  @Action(SetLoading)
  setLoading({ patchState, getState }: StateContext<UI>, data: SetLoading) {
    const { loadingData } = getState();
    patchState({ loadingData: { ...loadingData, ...data } });
  }

  @Action(ShareContent)
  shareContent({}: StateContext<UI>, { title, text, url }: ShareContent) {
    return Share.share({
      title,
      text,
      url,
    })
      .then(() => {
        if (window.navigator.vibrate) {
          window.navigator.vibrate(30);
        }
        return this.openSnackBar("Gracias por compartir", "😄", {
          duration: 3000,
          panelClass: ["custom-snackbar"],
        });
      })
      .catch(() => {
        if (window.navigator.vibrate) {
          window.navigator.vibrate(30);
        }
        return Clipboard.write({ string: url })
          .then(() => {
            this.openSnackBar("Link copiado con exito", "😄", {
              duration: 3000,
              panelClass: ["custom-error"],
            });
          })
          .catch(() => {
            return this.openSnackBar("Te agredecemos el intento", "😥", {
              duration: 3000,
              panelClass: ["custom-snackbar-error"],
            });
          });
      });
  }

  @Action(Vibrate)
  Vibrate({ duration }: Vibrate) {
    if (window.navigator.vibrate) {
      window.navigator.vibrate(duration);
    }
  }

  @Action(NativeApp)
  NativeApp({ patchState }: StateContext<UI>) {
    patchState({ native: true });
  }

  @Action(GetCarrousel)
  etCarrousel({ patchState }: StateContext<UI>) {
    return this.fs
      .collection("parameters")
      .doc("muyticoCarousel")
      .valueChanges()
      .pipe(
        tap((muyticoCarousel: any) => {
          patchState({ muyticoCarousel });
        })
      );
  }

  @Action(DownloadXML)
  downloadXML({}: StateContext<UI>, { id }: DownloadXML) {
    const { token } = this.store.selectSnapshot(UserState);
    return this.api.downloadXML(token, id).pipe(
      tap(({ data: { downloadXML } }) => {
        if (downloadXML) {
          const blob = new Blob([downloadXML], {
            type: "application/xml;charset=utf-8",
          });
          saveAs(blob, `${id}.xml`);
        }
      })
    );
  }

  @Action(DownloadRespXML)
  downloadRespXML({}: StateContext<UI>, { id }: DownloadRespXML) {
    const { token } = this.store.selectSnapshot(UserState);
    return this.api.downloadRespXML(token, id).pipe(
      tap(({ data: { downloadRespXML } }) => {
        if (downloadRespXML) {
          const blob = new Blob([downloadRespXML], {
            type: "application/xml;charset=utf-8",
          });
          saveAs(blob, `${id}.xml`);
        }
      })
    );
  }

  openSnackBar = (message: string, button: string, config?: any, cb?: any) => {
    this.zone.run(() => {
      if (cb) {
        cb();
      }
      return this.snackBar.open(message, button, config);
    });
  };
}
