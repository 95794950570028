import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl } from "@angular/forms";
import { MatSidenav } from "@angular/material/sidenav";
import {
  ofActionErrored,
  ofActionSuccessful,
  Select,
  Store,
} from "@ngxs/store";
import { Observable, Subject, Subscription } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  skipWhile,
  tap,
} from "rxjs/operators";
import { ResultSearchCriteria, Tag } from "src/app/interface/product";
import {
  AddCriteriaSearch,
  DeleteSearchCriteriaUser,
  FindProductFeatures,
  FindSearchCriteria,
  GetTagsForMarketSearch,
  ProductsByTag,
  ResetFinds,
  SetProductTag,
  TagsByFather,
} from "src/app/states/actions/product.actions";
import { SetLoading } from "src/app/states/actions/ui.actions";
import { ProductsState } from "src/app/states/product.state";
import { UIState } from "src/app/states/ui.state";

@Component({
  selector: "app-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.less"],
})
export class SearchComponent implements OnInit {
  @Select(ProductsState.findsFiltered)
  findsFiltered: Observable<ResultSearchCriteria>;
  @Select(ProductsState.tagsFiltered) tagsFiltered: Observable<Tag[]>;
  @Select(UIState.loading) loading;
  @ViewChild("searchInput", { static: true }) searchInput;
  @Input() store: Store;
  @Input() drawer: MatSidenav;
  @Input() utilities: { actions: any; store: Store };
  criteria: UntypedFormControl;
  searchTagsSubs: Subscription;
  searchCriteriaSubs: Subscription;
  searchCriteria = new Subject<string>();
  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit() {
    this.criteria = this.fb.control("");
    this.searchTagsSubs = this.searchCriteria
      .pipe(
        skipWhile((val) => val.trim() === ""),
        debounceTime(400),
        distinctUntilChanged(),
        tap((x) => {
          if (x.trim() !== "") {
            this.utilities.store.dispatch(
              new GetTagsForMarketSearch(this.criteria.value.trim())
            );
          }
        })
      )
      .subscribe();
    this.searchCriteriaSubs = this.searchCriteria
      .pipe(
        skipWhile((val) => val.trim() === ""),
        debounceTime(400),
        distinctUntilChanged(),
        tap((x) => {
          if (x.trim() !== "") {
            this.utilities.store.dispatch(
              new FindSearchCriteria(this.criteria.value.trim())
            );
          }
        })
      )
      .subscribe();
  }

  searchByFind = (criteria) => {
    if (criteria && criteria !== "") {
      this.utilities.store.dispatch(new SetLoading(true, 100));
      this.handler(FindProductFeatures);
      this.utilities.store.dispatch(
        new AddCriteriaSearch({ type: "criteria", criteria })
      );
    }
    this.utilities.store.dispatch(new ResetFinds());
    setTimeout(() => {
      if (this.searchInput) {
        this.searchInput.nativeElement.blur();
      }
    }, 500);
  };

  searchProduct = (criteria) => {
    this.utilities.store.dispatch(new ResetFinds());
    if (criteria && criteria !== "") {
      this.utilities.store.dispatch(new SetLoading(true, 100));
      this.handler(FindProductFeatures);
      this.utilities.store.dispatch(
        new AddCriteriaSearch({ type: "criteria", criteria })
      );
    }
  };

  searchByTag = (tag, needFind) => {
    if (tag && tag.id !== "Categorías") {
      const newTag = needFind ? needFind.find((t) => t.id === tag.id) : tag;
      this.criteria.setValue(newTag.Value);
      this.utilities.store.dispatch(new SetLoading(true, 100));
      this.handler(ProductsByTag);
      this.handler(AddCriteriaSearch);
      this.utilities.store.dispatch(
        new AddCriteriaSearch({
          type: "tag",
          criteria: newTag.id,
          tag: newTag.Value,
        })
      );
      this.utilities.store.dispatch(new SetProductTag(tag));
      if (newTag && newTag.TagCategory && newTag.TagCategory.id !== "3") {
        this.handler(TagsByFather);
        this.utilities.store.dispatch(new TagsByFather(newTag.id, false));
      }
      this.utilities.store.dispatch(new ResetFinds());
      setTimeout(() => {
        if (this.searchInput) {
          this.searchInput.nativeElement.blur();
        }
      }, 500);
    }
  };

  focus = (event) => {
    event.focus();
    if (event.value && event.value !== "") {
      this.searchByFind(event.value);
      event.blur();
    }
  };

  handler = (action) => {
    let errorSubs;
    let subs;
    errorSubs = this.utilities.actions
      .pipe(ofActionErrored(action))
      .subscribe(() => {
        errorSubs.unsubscribe();
        this.utilities.store.dispatch(new SetLoading(false, 0));
        subs.unsubscribe();
      });
    subs = this.utilities.actions
      .pipe(ofActionSuccessful(action))
      .subscribe(() => {
        errorSubs.unsubscribe();
        this.utilities.store.dispatch(new SetLoading(false, 0));
        subs.unsubscribe();
      });
  };

  removeSearchCriteria = (event, id) => {
    this.utilities.store.dispatch(new SetLoading(true, 100));
    this.handler(DeleteSearchCriteriaUser);
    event.stopImmediatePropagation();
    event.preventDefault();
    this.utilities.store.dispatch(new DeleteSearchCriteriaUser(id));
  };
}
