import { Apollo } from "apollo-angular";
import { Injectable } from "@angular/core";

import { HttpClient } from "@angular/common/http";
import {
  checkIfAccountIsEnough,
  getAllCountries,
  productsFeaturesByUpdate,
  productsFeaturesRelated,
  productsFeaturesRandom,
  getBillsByUserStateAndPlace,
  progressByBillId,
  allCoins,
  findSearchCriteria,
  getTagsForMarketSearch,
  deepInTree,
  findProductFeaturesByCriteriaPublic,
  searchTags,
  sonTags,
  isBillAvailable,
  myCart,
  getMHUserByFK_Person,
  featureById,
  muyTicoCategories,
  getCuponsByGoogleAuth,
  validatePhone,
  ninja,
  billToPay,
  cuponByid,
  getPlaceById,
  downloadXML,
  downloadRespXML,
  deepInTreebyTags,
  findBillsByStateAndUser,
  getCategoryTagsByPlace,
} from "./graphql/querys";
import {
  disableShowGuide,
  userByUID,
  registerUser,
  registerPerson,
  firebaseAuth,
  updateAddress,
  updateAddressState,
  createAddress,
  sendCoinsToWallet,
  updateUserProfilePicture,
  createBillReview,
  createProductReview,
  createBillDetail,
  updateBillDetailQuantity,
  removeBillDetail,
  createSearchCriteria,
  deleteSearchCriteriaUser,
  payCart,
  addClickToProductFeature,
  createNotAvailableAddressToSend,
  subtractCupon,
  suscribeToNotification,
  updateUserPhone,
  editDetailsBill,
  selectBillAddress,
  payCartNative,
  suscribeToBill,
  updateDevice,
  saveDeviceToken,
  subscribeToPlace,
  registerClient,
} from "./graphql/mutations";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  constructor(private apollo: Apollo, private http: HttpClient) {}

  context = (ctx) => {
    let headers = {};
    switch (typeof ctx) {
      case "string":
        if (ctx) {
          headers = Object.assign(headers, { Authorization: `Bearer ${ctx}` });
        }
        break;
      case "object":
        if (ctx?.token) {
          headers = Object.assign(headers, {
            Authorization: `Bearer ${ctx.token}`,
          });
        }
        if (ctx?.uuid) {
          headers = Object.assign(headers, { uuid: `${ctx.uuid}` });
        }
        break;
    }
    return { headers };
  };

  ///////////////////////////////////////
  /////////////QUERIES//////////////////
  //////////////////////////////////////

  findPlace = (query) =>
    this.http.get(`https://photon.komoot.io/api?q=${query}&lang=en&limit=8`);

  findProductFeaturesByCriteriaPublic = (
    token,
    criteria,
    limit,
    offset,
    filters,
    placeId
  ) =>
    this.apollo.query<any>({
      query: findProductFeaturesByCriteriaPublic,
      context: this.context(token),
      variables: { criteria, limit, offset, filters, placeId },
    });

  deepInTree = (tag, limit, offset, filters) =>
    this.apollo.query<any>({
      query: deepInTree,
      variables: { tag, limit, offset, filters },
    });

  deepInTreebyTags = (tags, limit, offset, filters) =>
    this.apollo.query<any>({
      query: deepInTreebyTags,
      variables: { tags, limit, offset, filters },
    });

  searchTags = (token, criteria) =>
    this.apollo.query<any>({
      query: searchTags,
      context: this.context(token),
      variables: { criteria },
    });

  findSearchCriteria = (token, criteria, limit, offset, userId) =>
    this.apollo.query<any>({
      context: this.context(token),
      query: findSearchCriteria,
      variables: { criteria, limit, offset, userId, FK_Module: 3 },
    });

  progressByBillId = (token, id) =>
    this.apollo.query<any>({
      query: progressByBillId,
      variables: { id },
      context: this.context(token),
    });

  checkIfAccountIsEnough = (token, FK_Account, Total) =>
    this.apollo.query<any>({
      query: checkIfAccountIsEnough,
      variables: { FK_Account, Total },
      context: this.context(token),
    });

  getAllCountries = () =>
    this.apollo.query<any>({
      query: getAllCountries,
    });

  productsFeaturesByUpdate = (token, limit, offset, placeId) =>
    this.apollo.query<any>({
      context: this.context(token),
      query: productsFeaturesByUpdate,
      variables: {
        limit,
        offset,
        placeId,
      },
    });

  productsFeaturesRelated = (tags, limit, offset) =>
    this.apollo.query<any>({
      query: productsFeaturesRelated,
      variables: {
        tags,
        limit,
        offset,
      },
    });

  productsFeaturesRandom = (limit, offset) =>
    this.apollo.query<any>({
      query: productsFeaturesRandom,
      variables: {
        limit,
        offset,
      },
    });

  getBillsByUserStateAndPlace = (token, states, limit, offset, placeId) =>
    this.apollo.query<any>({
      query: getBillsByUserStateAndPlace,
      context: this.context(token),
      variables: { states, limit, offset, placeId },
    });

  getTagsForMarketSearch = (criteria) =>
    this.apollo.query<any>({
      query: getTagsForMarketSearch,
      variables: { criteria },
    });

  sonTags = (token, id) =>
    this.apollo.query<any>({
      query: sonTags,
      context: this.context(token),
      variables: { id },
    });

  allCoins = () =>
    this.apollo.query<any>({
      query: allCoins,
    });

  isBillAvailable = (token, id) =>
    this.apollo.query<any>({
      query: isBillAvailable,
      variables: { id },
      context: this.context(token),
    });

  myCart = (token, placeId) =>
    this.apollo.query<any>({
      query: myCart,
      context: this.context(token),
      variables: { placeId },
    });

  featureById = (id) =>
    this.apollo.query<any>({
      query: featureById,
      variables: { id },
    });

  muyTicoCategories = () =>
    this.apollo.query<any>({
      query: muyTicoCategories,
    });

  getCuponsByGoogleAuth = (token) =>
    this.apollo.query<any>({
      query: getCuponsByGoogleAuth,
      context: this.context(token),
    });

  validatePhone = (phone, codeRegion) =>
    this.apollo.query<any>({
      query: validatePhone,
      variables: {
        phone,
        codeRegion,
      },
    });

  billToPay = (id) =>
    this.apollo.query<any>({
      query: billToPay,
      variables: id,
    });

  cuponByid = (id) =>
    this.apollo.query<any>({
      query: cuponByid,
      variables: id,
    });

  getPlaceById = (id) =>
    this.apollo.query<any>({
      query: getPlaceById,
      variables: { id },
    });

  downloadXML = (token, id) =>
    this.apollo.use("EB").query<any>({
      query: downloadXML,
      context: this.context(token),
      variables: { id },
    });

  downloadRespXML = (token, id) =>
    this.apollo.use("EB").query<any>({
      query: downloadRespXML,
      context: this.context(token),
      variables: { id },
    });

  findBillsByStateAndUser = (token: string, State_Id: number) =>
    this.apollo.query<any>({
      query: findBillsByStateAndUser,
      context: this.context(token),
      variables: { FK_Place: environment.placeId, State_Id },
    });

  getCategoryTagsByPlace = (FK_Place: number) =>
    this.apollo.query<any>({
      query: getCategoryTagsByPlace,
      variables: { FK_Place },
    });

  //////////////////////////////////////
  /////////////MUTATION/////////////////
  //////////////////////////////////////

  payCart = (token, cart) =>
    this.apollo.use("EB").mutate<any>({
      mutation: payCart,
      context: this.context(token),
      variables: { cart },
    });

  payCartNative = (cart) =>
    this.apollo.use("EB").mutate<any>({
      mutation: payCartNative,
      variables: { cart },
    });

  selectBillAddress = (token, billId, addressId) =>
    this.apollo.mutate<any>({
      mutation: selectBillAddress,
      variables: { billId, addressId },
      context: this.context(token),
    });

  getMHUserByFK_Person = (token, id) =>
    this.apollo.use("EB").query<any>({
      query: getMHUserByFK_Person,
      variables: { id },
      context: this.context(token),
    });

  suscribeToNotification = (token, notificationDevice) =>
    this.apollo.mutate<any>({
      mutation: suscribeToNotification,
      variables: { notificationDevice },
      context: this.context(token),
    });

  suscribeToBill = (token, notificationDevice) =>
    this.apollo.mutate<any>({
      mutation: suscribeToBill,
      variables: { notificationDevice },
      context: this.context(token),
    });

  removeBillDetail = (token, id) =>
    this.apollo.mutate<any>({
      mutation: removeBillDetail,
      context: this.context(token),
      variables: { id },
    });

  createBillDetail = (token, detail) =>
    this.apollo.mutate({
      mutation: createBillDetail,
      context: this.context(token),
      variables: { detail },
    });

  updateBillDetailQuantity = (token, id, quantity, total) =>
    this.apollo.mutate<any>({
      mutation: updateBillDetailQuantity,
      context: this.context(token),
      variables: { id, quantity, total },
    });

  createProductReview = (token, review) =>
    this.apollo.mutate<any>({
      mutation: createProductReview,
      variables: { review },
      context: this.context(token),
    });

  createBillReview = (token, review) =>
    this.apollo.mutate<any>({
      mutation: createBillReview,
      variables: {
        review,
      },
      context: this.context(token),
    });

  userByUID = (token, uid) =>
    this.apollo.mutate<any>({
      mutation: userByUID,
      context: this.context(token),
      variables: { uid },
    });

  ninja = (uid) =>
    this.apollo.use("EB").query<any>({
      query: ninja,
      variables: { uid },
    });

  disableShowGuide = (token) =>
    this.apollo.mutate<any>({
      mutation: disableShowGuide,
      context: this.context(token),
    });

  registerUser = (user) =>
    this.apollo.mutate<any>({
      mutation: registerUser,
      variables: { user },
    });

  registerPerson = (token, user) =>
    this.apollo.mutate<any>({
      mutation: registerPerson,
      context: this.context(token),
      variables: { user },
    });

  registerClient = (user) =>
    this.apollo.mutate<any>({
      mutation: registerClient,
      variables: { user, placeId: environment.placeId },
    });

  firebaseAuth = (user) =>
    this.apollo.mutate<any>({
      mutation: firebaseAuth,
      variables: { user },
    });

  updateAddressState = (token, id, State_Address) =>
    this.apollo.mutate<any>({
      mutation: updateAddressState,
      variables: {
        id,
        State_Address,
      },
      context: this.context(token),
    });

  updateAddress = (token, id, address) =>
    this.apollo.mutate<any>({
      mutation: updateAddress,
      context: this.context(token),
      variables: { id, address },
    });

  newAddress = (token, address) =>
    this.apollo.mutate<any>({
      mutation: createAddress,
      context: this.context(token),
      variables: { address },
    });

  sendCoinsToWallet = (token, emittingAccountId, receptingAccountId, amount) =>
    this.apollo.mutate<any>({
      mutation: sendCoinsToWallet,
      variables: {
        srlorytuak: emittingAccountId,
        ktndxlufpy: receptingAccountId,
        whwjwtyypj: amount,
      },
      context: this.context(token),
    });

  updateUserProfilePicture = (token, Route_File, FirebaseId) =>
    this.apollo.mutate<any>({
      mutation: updateUserProfilePicture,
      variables: { Route_File, FirebaseId },
      context: this.context(token),
    });

  createNotAvailableAddressToSend = (token, notAvailable) =>
    this.apollo.mutate<any>({
      mutation: createNotAvailableAddressToSend,
      variables: { notAvailable },
      context: this.context(token),
    });

  updateUserPhone = (token, newPhone) =>
    this.apollo.mutate<any>({
      mutation: updateUserPhone,
      variables: { newPhone },
      context: this.context(token),
    });

  editDetailsBill = (token, billId, newDetails) =>
    this.apollo.mutate<any>({
      mutation: editDetailsBill,
      variables: { newDetails, billId },
      context: this.context(token),
    });

  createSearchCriteria = (token, criteria) =>
    this.apollo.mutate<any>({
      mutation: createSearchCriteria,
      variables: { criteria },
      context: this.context(token),
    });

  deleteSearchCriteriaUser = (token, id) =>
    this.apollo.mutate<any>({
      mutation: deleteSearchCriteriaUser,
      variables: { id },
      context: this.context(token),
    });

  subtractCupon = (token, cuponId) =>
    this.apollo.mutate<any>({
      mutation: subtractCupon,
      variables: { cuponId },
      context: this.context(token),
    });

  addClickToProductFeature = (id) =>
    this.apollo.mutate<any>({
      mutation: addClickToProductFeature,
      variables: { id },
    });

  updateDevice = (device) =>
    this.apollo.mutate<any>({
      mutation: updateDevice,
      variables: { device },
    });

  saveDeviceToken = (ctx, token) =>
    this.apollo.mutate<any>({
      mutation: saveDeviceToken,
      variables: { token },
      context: this.context(ctx),
    });

  subscribeToPlace = (ctx, token, FK_Place) =>
    this.apollo.use("Places").mutate<any>({
      mutation: subscribeToPlace,
      variables: { token, FK_Place },
      context: this.context(ctx),
    });
}
