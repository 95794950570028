import { Component, OnInit, ViewChild, OnDestroy } from "@angular/core";
import { UserState } from "./states/user.state";
import {
  Select,
  Store,
  ofActionSuccessful,
  Actions,
  ofActionErrored,
} from "@ngxs/store";
import { Observable, Subject, Subscription, of } from "rxjs";
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
} from "@angular/forms";
import { SignOut } from "./states/actions/user.actions";
import { UIState } from "./states/ui.state";
import { MatDialog } from "@angular/material/dialog";
import { MatIconRegistry } from "@angular/material/icon";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
  skipWhile,
  debounceTime,
  distinctUntilChanged,
  tap,
  map,
  shareReplay,
} from "rxjs/operators";
import { DeviceDetectorService } from "ngx-device-detector";
import { Tag, ResultSearchCriteria } from "src/app/interface/product";
import { ProductsState } from "./states/product.state";
import {
  Breakpoints,
  BreakpointObserver,
  BreakpointState,
} from "@angular/cdk/layout";
import {
  GetTagsForMarketSearch,
  FindSearchCriteria,
  AddCriteriaSearch,
  TagsByFather,
  ResetFinds,
  SetProductTag,
  ProductsByTag,
  SetFeatureTags,
  ProductsByTags,
  FetchCategoryTags,
} from "./states/actions/product.actions";
import { InstallApp, SetLoading, SetMobile } from "./states/actions/ui.actions";
import { DomSanitizer } from "@angular/platform-browser";
declare let google;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.less"],
})
export class AppComponent implements OnInit, OnDestroy {
  mobile = false;
  loginForm: UntypedFormGroup;
  criteria: UntypedFormControl;
  searchCriteria = new Subject<string>();

  @Select(UserState.items) items: Observable<any>;
  @Select(UserState.coins) coins: Observable<any[]>;
  @Select(UIState.install) install: Observable<any>;
  @Select(UIState.native) native: Observable<boolean>;
  @Select(UserState.isGhost) isGhost: Observable<any>;
  @Select(UIState.loadingData) loadingData: Observable<any>;
  @Select(UIState.mobile) mobileDevice: Observable<boolean>;
  @Select(ProductsState.tagsFiltered) tagsFiltered: Observable<Tag[]>;
  @Select(ProductsState.categories) categories$: Observable<any[]>;
  @Select(ProductsState.findsFiltered)
  findsFiltered: Observable<ResultSearchCriteria>;
  searchTagsSubs: Subscription;
  searchCriteriaSubs: Subscription;
  isSmallScreen: boolean = false;

  @ViewChild("searchInput", { static: true }) searchInput;
  productTag: any;
  activeFeatureTag: any;
  featureTagsActives: Tag[] = [];
  translated: any;
  constructor(
    private store: Store,
    private fb: UntypedFormBuilder,
    private actions: Actions,
    private snack: MatSnackBar,
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
    private deviceService: DeviceDetectorService,
    public breakpointObserver: BreakpointObserver,
    private dialog: MatDialog
  ) {
    this.matIconRegistry.addSvgIcon(
      "custom-add-to-cart",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "./assets/icons/add-to-cart.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "free-shipping",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "./assets/icons/free.svg"
      )
    );
  }

  ngOnDestroy() {
    this.searchTagsSubs.unsubscribe();
  }

  ngOnInit() {
    const node = document.createElement("script");
    node.src = "https://kit.fontawesome.com/b6d780991c.js";
    node.type = "text/javascript";
    document.getElementsByTagName("head")[0].appendChild(node);

    const nodeTranslate = document.createElement("script");
    nodeTranslate.src = "//translate.google.com/translate_a/element.js";
    nodeTranslate.type = "text/javascript";
    document.getElementsByTagName("head")[0].appendChild(nodeTranslate);
    this.store.select(ProductsState.productTag).subscribe((tag) => {
      this.productTag = tag;
    });
    this.store
      .select(ProductsState.featureTag)
      .subscribe((tag) => this.featureTagsActives.push(tag));
    this.breakpointObserver
      .observe([Breakpoints.Small, Breakpoints.XSmall])
      .subscribe((state: BreakpointState) => {
        this.store.dispatch(new SetMobile(state.matches));
      });
    this.criteria = this.fb.control("");
    this.searchTagsSubs = this.searchCriteria
      .pipe(
        skipWhile((val) => val.trim() === ""),
        debounceTime(400),
        distinctUntilChanged(),
        tap((x) => {
          if (x.trim() !== "") {
            this.store.dispatch(
              new GetTagsForMarketSearch(this.criteria.value.trim())
            );
          }
        })
      )
      .subscribe();
    this.searchCriteriaSubs = this.searchCriteria
      .pipe(
        skipWhile((val) => val.trim() === ""),
        debounceTime(400),
        distinctUntilChanged(),
        tap((x) => {
          if (x.trim() !== "") {
            this.store.dispatch(
              new FindSearchCriteria(this.criteria.value.trim())
            );
          }
        })
      )
      .subscribe();
    this.store.dispatch(new FetchCategoryTags());
    this.breakpointObserver
      .observe(["(max-width: 800px)"])
      .pipe(
        map((result) => result.matches),
        shareReplay()
      )
      .subscribe((matches) => (this.isSmallScreen = matches));
  }
  get istMobile() {
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    return isMobile || isTablet;
  }

  searchByTag = (tag, needFind) => {
    if (tag && tag.id !== "Categorías") {
      const newTag = needFind ? needFind.find((t) => t.id === tag.id) : tag;
      this.criteria.setValue(newTag.Value);
      this.store.dispatch(new SetLoading(true, 100));
      this.handler(ProductsByTag);
      this.handler(AddCriteriaSearch);
      this.store.dispatch(
        new AddCriteriaSearch({
          type: "tag",
          criteria: newTag.id,
          tag: newTag.Value,
        })
      );
      this.store.dispatch(new SetProductTag(tag));
      if (newTag && newTag.TagCategory && newTag.TagCategory.id !== "3") {
        this.handler(TagsByFather);
        this.store.dispatch(new TagsByFather(newTag.id, false));
      }

      this.store.dispatch(new ResetFinds());
      setTimeout(() => {
        if (this.searchInput) {
          this.searchInput.nativeElement.blur();
        }
      }, 500);
    }
  };

  logout = () => this.store.dispatch(new SignOut());

  top(progress) {
    return `${progress}px`;
  }

  opacity(progress) {
    return progress / 100;
  }

  noItemsInCart = () => {
    this.snack.open("Sin productos en el carrito", "Ok", { duration: 3000 });
  };

  handler = (action) => {
    let errorSubs;
    let subs;
    errorSubs = this.actions.pipe(ofActionErrored(action)).subscribe(() => {
      errorSubs.unsubscribe();
      this.store.dispatch(new SetLoading(false, 0));
      subs.unsubscribe();
    });
    subs = this.actions.pipe(ofActionSuccessful(action)).subscribe(() => {
      errorSubs.unsubscribe();
      this.store.dispatch(new SetLoading(false, 0));
      subs.unsubscribe();
    });
  };

  installApp = () => this.store.dispatch(new InstallApp());

  searchProductTag = (tag) => {
    if (tag === this.store.selectSnapshot(ProductsState.productTag)) {
      this.store.dispatch(new SetProductTag(null));
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
      this.store.dispatch(new SetProductTag(tag));
      this.store.dispatch(
        new AddCriteriaSearch({ type: "tag", criteria: tag.id, tag: tag.Value })
      );
      this.featureTagsActives = this.store.selectSnapshot(
        ProductsState.productTag
      ).FeatureTags;
    }
  };

  inList = (feature) => {
    return this.featureTagsActives.includes(feature);
  };

  checkFeature = ({ checked }, tag) => {
    if (checked) {
      this.featureTagsActives.push(tag);
    } else {
      this.featureTagsActives = this.featureTagsActives.filter(
        (xfeature) => xfeature.id !== tag.id
      );
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (this.featureTagsActives.length > 0) {
      const tags = this.featureTagsActives.map((x) => x.id);
      this.store.dispatch(new SetFeatureTags(this.featureTagsActives));
      this.store.dispatch(new ProductsByTags(tags));
    }
  };

  get topPx() {
    const { scrollHeight } = document.querySelector("#muyticoToolbar");
    return of(`${scrollHeight}px `);
  }

  get topValue() {
    const { scrollHeight } = document.querySelector("#muyticoToolbar");
    return of(scrollHeight);
  }

  translate = () => {
    this.translated = new google.translate.TranslateElement(
      {
        pageLanguage: "es-CR",
        layout: google.translate.TranslateElement.InlineLayout.SIMPLE,
        includedLanguages:
          "ar,ca,co,cs,fr,gl,et,de,en,es,zh-CN,zh-TW,gl,el,he,it,ja,ko,la,ro,ru",
      },
      "google_translate_element"
    );
  };

  get utilities() {
    return { store: this.store, actions: this.actions };
  }
}
