import { NgModule } from '@angular/core';
import { DetailsPipe } from './details/details.pipe';
import { ImgPipe } from './img/img.pipe';
import { FinalPricePipe } from './finalPrice/final-price.pipe';
import { CoinConversionPipe } from './coin-conversion/coin-conversion.pipe';
import { PaymentConversionPipe } from './payment-conversion/payment-conversion.pipe';
@NgModule({
  declarations: [
    DetailsPipe,
    ImgPipe,
    FinalPricePipe,
    CoinConversionPipe,
    PaymentConversionPipe,
  ],
  exports: [
    DetailsPipe,
    ImgPipe,
    FinalPricePipe,
    CoinConversionPipe,
    PaymentConversionPipe,
  ]
})
export class BasicPipesModule { }
