
const degreesToRadians = (degrees) => {
  if (degrees === null || degrees === undefined) {
    throw new Error('degrees is required');
  }
  const radians = degrees % 360;
  return radians * Math.PI / 180;
};

const isObject = (input) => {
  return (!!input) && (input.constructor === Object);
};

const isNumber = (num) => {
  return !isNaN(num) && num !== null && !Array.isArray(num);
};

const containsNumber = (coordinates) => {
  if (coordinates.length > 1 && isNumber(coordinates[0]) && isNumber(coordinates[1])) {
    return true;
  }

  if (Array.isArray(coordinates[0]) && coordinates[0].length) {
    return containsNumber(coordinates[0]);
  }
  throw new Error('coordinates must only contain numbers');
};

const getCoords = (obj) => {
  if (!obj) {
    throw new Error('obj is required');
  }
  let coordinates;
  if (obj.length) {
    coordinates = obj;
  } else if (obj.coordinates) {
    coordinates = obj.coordinates;
  } else if (obj.geometry && obj.geometry.coordinates) {
    coordinates = obj.geometry.coordinates;
  }
  if (coordinates) {
    containsNumber(coordinates);
    return coordinates;
  }
  throw new Error('No valid coordinates');
};

const getCoord = (obj) => {
  if (!obj) {
    throw new Error('obj is required');
  }
  const coordinates = getCoords(obj);
  if (coordinates.length > 1 && isNumber(coordinates[0]) && isNumber(coordinates[1])) {
    return coordinates;
  } else {
    throw new Error('Coordinate is not a valid Point');
  }
};


const radiansToLength = (radians, units) => {
  const earthRadius = 6371008.8;
  const factors = {
    meters: earthRadius,
    metres: earthRadius,
    millimeters: earthRadius * 1000,
    millimetres: earthRadius * 1000,
    centimeters: earthRadius * 100,
    centimetres: earthRadius * 100,
    kilometers: earthRadius / 1000,
    kilometres: earthRadius / 1000,
    miles: earthRadius / 1609.344,
    nauticalmiles: earthRadius / 1852,
    inches: earthRadius * 39.370,
    yards: earthRadius / 1.0936,
    feet: earthRadius * 3.28084,
    radians: 1,
    degrees: earthRadius / 111325,
  };
  if (radians === undefined || radians === null) { throw new Error('radians is required'); }
  if (units && typeof units !== 'string') { throw new Error('units must be a string'); }
  const factor = factors[units || 'kilometers'];
  if (!factor) { throw new Error(units + ' units is invalid'); }
  return radians * factor;
};

export const distance = (from, to, options) => {
  // Optional parameters
  options = options || {};
  if (!isObject(options)) {
    throw new Error('options is invalid');
  }
  const units = options.units;
  const coordinates1 = getCoord(from);
  const coordinates2 = getCoord(to);
  const dLat = degreesToRadians((coordinates2[1] - coordinates1[1]));
  const dLon = degreesToRadians((coordinates2[0] - coordinates1[0]));
  const lat1 = degreesToRadians(coordinates1[1]);
  const lat2 = degreesToRadians(coordinates2[1]);
  const a = Math.pow(Math.sin(dLat / 2), 2) +
    Math.pow(Math.sin(dLon / 2), 2) * Math.cos(lat1) * Math.cos(lat2);

  return radiansToLength(2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a)), units);
};









