import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { UserState } from 'src/app/states/user.state';
import { switchMap } from 'rxjs/operators';
import { ProductPrice } from 'src/app/interface/shop';

@Pipe({
  name: 'finalPrice'
})
export class FinalPricePipe implements PipeTransform {

  constructor(private store: Store) { }

  transform(price: ProductPrice, quantity?: number, detailPrice?: number, showDiscount?: boolean): Observable<any> {
    return this.store.select(UserState.defaultCoin)
      .pipe(
        switchMap(coin => {
          const finalPrice = this.getFinalPrice(price, detailPrice, showDiscount);
          if (coin) {
            let newPrice = finalPrice / coin.Buy_Coin;
            if (quantity) {
              newPrice = newPrice * quantity;
            }
            return of(`${coin.Symbol_Coin}${newPrice.toLocaleString(undefined, { maximumFractionDigits: 2 })}`);
          }
          const coins = this.store.selectSnapshot(UserState.allCoins);
          if (!coins) {
            return of(`$${finalPrice.toLocaleString(undefined, { maximumFractionDigits: 2 })}`);
          }
          const [defaultCoin] = coins;
          let defaultPrice = finalPrice / defaultCoin.Buy_Coin;
          if (quantity) {
            defaultPrice = defaultPrice * quantity;
          }
          return of(`${defaultCoin.Symbol_Coin} ${defaultPrice.toLocaleString(undefined, { maximumFractionDigits: 2 })}`);
        })
      );
  }

  getFinalPrice = ({ Price_ProductPrice, Discount, Tariff, Cabys }: ProductPrice, detailPrice: number, showDiscount) => {
    let price = detailPrice ? detailPrice : Price_ProductPrice;
    if (Discount && showDiscount) {
      price = price * (100 - Discount.Quantity) / 100;
    }
    if (Cabys && Cabys.Percent) {
      return price * (1 + Cabys.Percent / 100);
    } else {
      return price;
    }
  }
}
