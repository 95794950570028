import * as _ from "lodash";
import { LocationService } from "./../services/location.service";
import {
  State,
  Action,
  StateContext,
  Selector,
  Store,
  createSelector,
} from "@ngxs/store";
import { AllProducts } from "../interface/product";
import { ApiService } from "../services/api.service";
import { tap, catchError, take } from "rxjs/operators";
import { UserState } from "./user.state";
import { Navigate } from "@ngxs/router-plugin";
import { of } from "rxjs";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
  FindProductFeatures,
  FilterByRadius,
  ProductsByTag,
  TagsByFather,
  GetTagsForMarketSearch,
  FindSearchCriteria,
  AddCriteriaSearch,
  SearchProductsFromState,
  ResetFinds,
  CreateSearchCriteria,
  DeleteSearchCriteriaUser,
  GetProductFeatureById,
  AddClickToFeature,
  SetMetaFeature,
  CleanFilteredFeatures,
  MuyTicoCategories,
  UpdateFeaturesInSearch,
  UpdateSelectedFeature,
  SetProductTag,
  SetFeatureTag,
  SetFilterPrice,
  SetFeatureTags,
  ProductsByTags,
} from "./actions/product.actions";
import { User } from "../interface/user";
import { Title, Meta } from "@angular/platform-browser";
import { ProductFeature } from "../interface/shop";
import { ProductsFeaturesRelated } from "./actions/user.actions";
import { Injectable } from "@angular/core";
const categories = require("../helpers/categories.json");
import { patch, removeItem, updateItem } from "@ngxs/store/operators";
import { AngularFireAnalytics } from "@angular/fire/compat/analytics";
import { environment } from "src/environments/environment";

@State<AllProducts>({
  name: "products",
  defaults: {
    tags: [],
    tagsToAssign: [],
    tagsFiltered: [],
    productCategoryTags: [],
    findsFiltered: null,
    products: [],
    selectedProduct: {},
    filteredFeatures: null,
    usedTags: [],
    hiOrderTags: [],
    filters: [],
    randomCode: "",
    searchCriteria: {
      type: "home",
    },
    loadMoreFindProducts: true,
    father: null,
    grandFather: null,
    searchByTag: null,
    loadingFinder: false,
    muyTicoCategories: [],
    searchFilter: {
      PriceOrder: null,
    },
  },
})
@Injectable()
export class ProductsState {
  constructor(
    private api: ApiService,
    private store: Store,
    private geoService: LocationService,
    private snack: MatSnackBar,
    private title: Title,
    private meta: Meta,
    private analytics: AngularFireAnalytics
  ) {}

  @Selector()
  static products(state: AllProducts) {
    return state.products;
  }

  @Selector()
  static findsFiltered(state: AllProducts) {
    return state.findsFiltered;
  }

  @Selector()
  static loadMoreFindProducts(state: AllProducts) {
    return state.loadMoreFindProducts;
  }

  @Selector()
  static filteredFeatures(state: AllProducts) {
    return state.filteredFeatures;
  }

  @Selector()
  static filters(state: AllProducts) {
    return state.filters;
  }

  @Selector()
  static searchByTag(state: AllProducts) {
    return state.searchByTag;
  }

  @Selector()
  static usedTags(state: AllProducts) {
    return state.usedTags;
  }

  @Selector()
  static tags(state: AllProducts) {
    return state.tags;
  }

  @Selector()
  static tagsToAssign(state: AllProducts) {
    return state.tagsToAssign;
  }

  @Selector()
  static tagsFiltered(state: AllProducts) {
    return state.tagsFiltered;
  }

  @Selector()
  static productCategoryTags(state: AllProducts) {
    return state.productCategoryTags;
  }

  @Selector()
  static loadingFinder(state: AllProducts) {
    return state.loadingFinder;
  }

  @Selector()
  static selectedProduct(state: AllProducts) {
    return state.selectedProduct;
  }

  @Selector()
  static hiOrderTags({ hiOrderTags }: AllProducts) {
    return hiOrderTags;
  }

  @Selector()
  static father({ father }: AllProducts) {
    return father;
  }

  @Selector()
  static grandFather({ grandFather }: AllProducts) {
    return grandFather;
  }

  @Selector()
  static searchCriteria({ searchCriteria }: AllProducts) {
    return searchCriteria;
  }

  @Selector()
  static selectedFeature({ selectedFeature }: AllProducts) {
    return selectedFeature;
  }

  @Selector()
  static productTag({ productTag }: AllProducts) {
    return productTag;
  }

  @Selector()
  static SearchUI(state: AllProducts) {
    const { muyTicoCategories, searchCriteria, productTag, featureTag } = state;
    const currentTag = muyTicoCategories.find(
      (tag) => tag.id === searchCriteria.criteria
    );
    const icon = currentTag
      ? categories.find((xIcon) => xIcon.id === currentTag.id)
      : undefined;
    const ui = {
      searchCriteria,
      currentTag,
      icon,
      muyTicoCategories,
      productTag,
      featureTag,
    };
    return ui;
  }

  @Selector()
  static currentTag({ muyTicoCategories, searchCriteria }: AllProducts) {
    const currentTag = muyTicoCategories.find(
      (tag) => tag.id === searchCriteria.criteria
    );
    const icon = currentTag
      ? categories.find((xIcon) => xIcon.id === currentTag.id)
      : null;
    const ui = {
      currentTag,
      icon,
    };
    return currentTag;
  }

  @Selector()
  static icon({ muyTicoCategories, searchCriteria }: AllProducts) {
    const icon = categories.find(
      (xIcon) => xIcon.id === searchCriteria.criteria
    );
    return icon;
  }

  @Selector()
  static searchFilters({ searchFilter }: AllProducts) {
    return searchFilter;
  }

  static inCart() {
    return createSelector(
      [UserState, ProductsState],
      (
        {
          firebaseUser: {
            TavuelUser: { Cart },
          },
        }: User,
        { selectedFeature }: AllProducts
      ) => {
        const detail = Cart.Detail.find(
          (x) => x.ProductFeature.id === selectedFeature.id
        );
        return detail;
      }
    );
  }

  @Selector()
  static muyTicoCategories({ muyTicoCategories }: AllProducts) {
    return muyTicoCategories;
  }

  @Selector()
  static featureTags({ featureTags }: AllProducts) {
    return featureTags;
  }

  @Selector()
  static featureTag({ featureTag }: AllProducts) {
    return featureTag;
  }

  @Action(FindProductFeatures)
  FindProductFeatures(
    { patchState, dispatch, getState, setState }: StateContext<AllProducts>,
    {}: FindProductFeatures
  ) {
    const { filteredFeatures, loadMoreFindProducts, filters, searchFilter } =
      getState();
    if (!loadMoreFindProducts) {
      patchState({
        loadingFinder: true,
      });
    }
    const limit = 12;
    const { searchCriteria } = getState();
    const offset = !loadMoreFindProducts ? 0 : filteredFeatures.length;
    const filterss = this.store.selectSnapshot(ProductsState.searchFilters);
    if (searchCriteria.type === "criteria") {
      const token = this.store.selectSnapshot(UserState.token);
      // 3 es el place id de muy tico
      //80 es el place id de tavuel
      return this.api
        .findProductFeaturesByCriteriaPublic(
          token,
          searchCriteria.criteria,
          limit,
          offset,
          filterss,
          environment.placeId
        )
        .pipe(
          tap(
            ({ data }) => {
              if (data && data.Features) {
                const newFeatures = data.Features ? data.Features : [];
                patchState({
                  searchByTag: null,
                  filteredFeatures: !loadMoreFindProducts
                    ? data.Features
                    : [...filteredFeatures, ...newFeatures],
                  loadMoreFindProducts:
                    newFeatures.length < limit || newFeatures.length === 0
                      ? false
                      : true,
                });
                const firebaseUser = this.store.selectSnapshot(
                  UserState.firebaseUser
                );
                if (
                  firebaseUser &&
                  firebaseUser.TavuelUser &&
                  firebaseUser.TavuelUser.Cart &&
                  firebaseUser.TavuelUser.Cart.Detail &&
                  firebaseUser.TavuelUser.Cart.Detail.length > 0
                ) {
                  data.Features.forEach((f) => {
                    const itemInCar = firebaseUser.TavuelUser.Cart.Detail.find(
                      (d) => d.ProductFeature.id === f.id
                    );
                    if (itemInCar) {
                      setState(
                        patch({
                          filteredFeatures: updateItem<any>(
                            (xF) => xF.id === itemInCar.ProductFeature.id,
                            patch({ isAdded: itemInCar.Quantity_BillDetail })
                          ),
                        })
                      );
                    }
                  });
                }
                const nearPlaces = filters.find((p) => p.type === "nearPlaces");
                if (nearPlaces) {
                  dispatch(new FilterByRadius());
                }
              } else {
                patchState({
                  searchByTag: null,
                  loadMoreFindProducts: null,
                  filteredFeatures: [],
                });
              }
              dispatch(
                new Navigate(["/search-result"], {
                  criteria: searchCriteria.criteria,
                })
              );
              patchState({
                loadingFinder: false,
              });
              this.analytics.logEvent("FindProductFeatures", {
                criteria: searchCriteria.criteria,
              });
            },
            (error) => {
              this.snack.open(error.message, "OK", {
                duration: 3000,
                panelClass: ["custom-snackbar-error"],
              });
            }
          )
        );
    }
    return this.api
      .deepInTree(searchCriteria.criteria, limit, offset, searchFilter)
      .pipe(
        tap(
          ({
            data: {
              result: { Features, SonTags, FatherTags },
            },
          }) => {
            patchState({
              filteredFeatures: !loadMoreFindProducts
                ? Features
                : [...filteredFeatures, ...Features],
              usedTags: SonTags,
              hiOrderTags: FatherTags,
              searchByTag: searchCriteria.criteria,
              loadMoreFindProducts:
                Features.length < limit || Features.length === 0 ? false : true,
            });
            const firebaseUser = this.store.selectSnapshot(
              UserState.firebaseUser
            );
            if (
              firebaseUser &&
              firebaseUser.TavuelUser &&
              firebaseUser.TavuelUser.Cart &&
              firebaseUser.TavuelUser.Cart.Detail &&
              firebaseUser.TavuelUser.Cart.Detail.length > 0
            ) {
              Features.forEach((f) => {
                const itemInCar = firebaseUser.TavuelUser.Cart.Detail.find(
                  (d) => d.ProductFeature.id === f.id
                );
                if (itemInCar) {
                  setState(
                    patch({
                      filteredFeatures: updateItem<any>(
                        (xF) => xF.id === itemInCar.ProductFeature.id,
                        patch({ isAdded: itemInCar.Quantity_BillDetail })
                      ),
                    })
                  );
                }
              });
            }
            const nearPlaces = filters.find((p) => p.type === "nearPlaces");
            if (nearPlaces) {
              dispatch(new FilterByRadius());
            }
            if (!window.location.pathname.includes("search-result")) {
              dispatch(new Navigate(["search-result"]));
            }
            patchState({
              loadingFinder: false,
            });
          }
        ),
        catchError((error) => {
          this.snack.open(error, "OK", {
            duration: 3000,
            panelClass: ["custom-snackbar-error"],
          });
          return of(null);
        })
      );
  }

  @Action(TagsByFather)
  TagsByFather(
    { patchState }: StateContext<AllProducts>,
    { father, isAdmin }: TagsByFather
  ) {
    const token = this.store.selectSnapshot(UserState.token);
    return this.api.sonTags(token, father).pipe(
      tap(({ data: { tags } }) => {
        isAdmin ? patchState({ tags }) : patchState({ tagsFiltered: tags });
        this.analytics.logEvent("TagsByFather");
      }),
      catchError(() => {
        this.snack.open("Error al obtener los tags por padre", "OK", {
          duration: 3000,
          panelClass: ["custom-snackbar-error"],
        });
        return of(null);
      })
    );
  }

  @Action(UpdateFeaturesInSearch)
  updateFeaturesInSearch(
    { patchState, getState, setState }: StateContext<AllProducts>,
    { id, quantity }: UpdateFeaturesInSearch
  ) {
    let { filteredFeatures } = getState();
    if (filteredFeatures && filteredFeatures.length > 0) {
      setState(
        patch({
          filteredFeatures: updateItem<any>(
            (xF) => xF.id === id,
            patch({ isAdded: quantity })
          ),
        })
      );
    }
  }

  @Action(UpdateSelectedFeature)
  updateSelectedFeature(
    { patchState, getState, setState }: StateContext<AllProducts>,
    { id, quantity }: UpdateSelectedFeature
  ) {
    const { selectedFeature } = getState();
    if (selectedFeature && selectedFeature.id && id === selectedFeature.id) {
      setState(patch({ selectedFeature: patch({ isAdded: quantity }) }));
    }
  }

  @Action(ProductsByTags)
  ProductsByTags(
    { patchState, dispatch, getState, setState }: StateContext<AllProducts>,
    { tags }: ProductsByTags
  ) {
    const {
      loadMoreFindProducts,
      filteredFeatures,
      filters,
      searchCriteria,
      searchFilter,
    } = getState();
    if (!loadMoreFindProducts) {
      patchState({
        loadingFinder: true,
      });
    }
    const limit = 16;
    const offset = 0;

    return this.api.deepInTreebyTags(tags, limit, offset, searchFilter).pipe(
      tap(
        ({
          data: {
            result: { Features, SonTags, FatherTags, FeatureTags },
          },
        }) => {
          patchState({
            filteredFeatures: filteredFeatures ? [...Features] : Features,
            usedTags: SonTags,
            hiOrderTags: FatherTags,
            searchByTag: tags[0],
            featureTags: FeatureTags,
            loadMoreFindProducts: Features.length > limit ? false : true,
          });
          patchState({
            loadingFinder: false,
          });
        }
      ),
      catchError((error) => {
        this.snack.open(error, "OK", {
          duration: 3000,
          panelClass: ["custom-snackbar-error"],
        });
        return of(null);
      })
    );
  }

  @Action(ProductsByTag)
  ProductsByTag(
    { patchState, dispatch, getState, setState }: StateContext<AllProducts>,
    { tag, reset }: ProductsByTag
  ) {
    const {
      loadMoreFindProducts,
      filteredFeatures,
      filters,
      searchCriteria,
      searchFilter,
    } = getState();
    if (!loadMoreFindProducts) {
      patchState({
        loadingFinder: true,
      });
    }
    const limit = 12;
    const places = filters.filter((filter) => filter.type === "place");
    const priceOrder = filters.find((filter) => filter.type === "priceOrder");
    const newFilters = {
      Places: places && places.length > 0 ? places.map((p) => p.id) : null,
      PriceOrder: priceOrder ? priceOrder.order : null,
    };
    const offset = !loadMoreFindProducts ? 0 : filteredFeatures.length;
    return this.api.deepInTree(tag, limit, offset, searchFilter).pipe(
      tap(
        ({
          data: {
            result: { Features, SonTags, FatherTags, FeatureTags },
          },
        }) => {
          this.analytics.logEvent("ProductsByTag");
          const { father } = getState();
          if (reset || !father) {
            patchState({ grandFather: null });
          } else if (father) {
            patchState({ grandFather: father });
          }
          patchState({
            filteredFeatures: filteredFeatures
              ? [...filteredFeatures, ...Features]
              : Features,
            usedTags: SonTags,
            hiOrderTags: FatherTags,
            searchByTag: tag,
            featureTags: FeatureTags,
            loadMoreFindProducts:
              Features.length < limit || Features.length === 0 ? false : true,
          });
          const firebaseUser = this.store.selectSnapshot(
            UserState.firebaseUser
          );
          if (
            firebaseUser &&
            firebaseUser.TavuelUser &&
            firebaseUser.TavuelUser.Cart &&
            firebaseUser.TavuelUser.Cart.Detail &&
            firebaseUser.TavuelUser.Cart.Detail.length > 0
          ) {
            Features.forEach((f) => {
              const itemInCar = firebaseUser.TavuelUser.Cart.Detail.find(
                (d) => d.ProductFeature.id === f.id
              );
              if (itemInCar) {
                setState(
                  patch({
                    filteredFeatures: updateItem<any>(
                      (xF) => xF.id === itemInCar.ProductFeature.id,
                      patch({ isAdded: itemInCar.Quantity_BillDetail })
                    ),
                  })
                );
              }
            });
          }

          const nearPlaces = filters.find((p) => p.type === "nearPlaces");
          if (nearPlaces) {
            dispatch(new FilterByRadius());
          }
          dispatch(
            new Navigate(["/search-result"], {
              tag: searchCriteria.tag,
              category: searchCriteria.criteria,
            })
          );
          patchState({
            loadingFinder: false,
          });
        }
      ),
      catchError((error) => {
        this.snack.open(error, "OK", {
          duration: 3000,
          panelClass: ["custom-snackbar-error"],
        });
        return of(null);
      })
    );
  }

  @Action(FilterByRadius)
  FilterByRadius({ patchState, getState }: StateContext<AllProducts>) {
    const { filteredFeatures, filters } = getState();
    const nearPlaces = filters.find((p) => p.type === "nearPlaces");
    if (nearPlaces && window && window.navigator) {
      window.navigator.geolocation.getCurrentPosition((pos) => {
        const placesToEvaluate = [];
        filteredFeatures.forEach((p) => {
          if (
            !placesToEvaluate.find(
              (e) => e.id === p.Product.Inventory.Cellar.Place.id
            )
          ) {
            placesToEvaluate.push({
              id: p.Product.Inventory.Cellar.Place.id,
              lat: parseFloat(
                p.Product.Inventory.Cellar.Place.Address.Lat_Address
              ),
              lng: parseFloat(
                p.Product.Inventory.Cellar.Place.Address.Lng_Address
              ),
            });
          }
        });
        this.geoService
          .getDistance(
            { lat: pos.coords.latitude, lng: pos.coords.longitude },
            placesToEvaluate
          )
          .subscribe((calc: any) => {
            placesToEvaluate.forEach(
              (point) =>
                (point.distance = calc.find((c) => c.id === point.id).distance)
            );
            const filteredByRadius = placesToEvaluate.filter(
              (p) => p.distance <= nearPlaces.radius
            );
            const productsWithDistance = filteredFeatures
              .map((p) => {
                const product = filteredByRadius.find(
                  (f) => f.id === p.Product.Inventory.Cellar.Place.id
                );
                if (product) {
                  return { ...p, distance: product.distance };
                }
              })
              .filter((p) => p);
            const result = productsWithDistance.sort((a, b) => {
              if (nearPlaces.order === "asc") {
                // mas lejanos al parecer
                if (a.distance > b.distance) {
                  return 1;
                }
                if (a.distance < b.distance) {
                  return -1;
                }
                return 0;
              } else {
                // mas cercanos
                if (a.distance < b.distance) {
                  return 1;
                }
                if (a.distance > b.distance) {
                  return -1;
                }
                return 0;
              }
            });
            patchState({
              filteredFeatures: result,
            });
          });
      });
    }
  }

  @Action(GetTagsForMarketSearch)
  GetTagsForMarketSearch(
    { patchState }: StateContext<AllProducts>,
    { criteria }: GetTagsForMarketSearch
  ) {
    return this.api.getTagsForMarketSearch(criteria).pipe(
      tap(({ data: { tags } }) => {
        patchState({ tagsFiltered: tags });
      })
    );
  }

  @Action(FindSearchCriteria)
  FindSearchCriteria(
    { patchState }: StateContext<AllProducts>,
    { criteria }: FindSearchCriteria
  ) {
    const { firebaseUser } = this.store.selectSnapshot(UserState);
    const token = this.store.selectSnapshot(UserState.token);
    return this.api
      .findSearchCriteria(
        token,
        criteria,
        10,
        0,
        firebaseUser && firebaseUser.TavuelUser
          ? firebaseUser.TavuelUser.id
          : null
      )
      .pipe(
        tap(({ data: { searchCriteria } }) => {
          patchState({ findsFiltered: searchCriteria });
        })
      );
  }

  @Action(AddCriteriaSearch)
  AddCriteriaSearch(
    { patchState, dispatch }: StateContext<AllProducts>,
    { criteria }: AddCriteriaSearch
  ) {
    patchState({
      searchCriteria: criteria,
      filteredFeatures: null,
      loadMoreFindProducts: false,
    });
    dispatch(new SearchProductsFromState());
  }

  @Action(SearchProductsFromState)
  SearchProductsFromState({ getState, dispatch }: StateContext<AllProducts>) {
    const { searchCriteria } = getState();
    if (searchCriteria && searchCriteria.type === "criteria") {
      dispatch(new FindProductFeatures());
      dispatch(new CreateSearchCriteria());
    } else if (searchCriteria && searchCriteria.type === "tag") {
      dispatch(
        new ProductsByTag(searchCriteria.criteria, searchCriteria.reset)
      );
      dispatch(new CreateSearchCriteria());
    }

    dispatch(new ResetFinds());
  }

  @Action(ResetFinds)
  ResetFinds({ patchState }: StateContext<AllProducts>, {}: ResetFinds) {
    patchState({
      tagsFiltered: [],
      findsFiltered: null,
    });
  }

  @Action(DeleteSearchCriteriaUser)
  DeleteSearchCriteriaUser(
    { setState }: StateContext<AllProducts>,
    { id }: DeleteSearchCriteriaUser
  ) {
    const { token } = this.store.selectSnapshot(UserState);
    if (token) {
      return this.api.deleteSearchCriteriaUser(token, id).pipe(
        tap(({ data: { deleted } }) => {
          this.analytics.logEvent("DeleteSearchCriteriaUser");
          if (deleted) {
            setState(
              patch({
                findsFiltered: patch({
                  searchCriteriasRelatedByUser: removeItem<any>(
                    (c) => c.FK_SearchCriteriaUser === id
                  ),
                }),
              })
            );
          }
        })
      );
    }
  }

  @Action(CreateSearchCriteria)
  createSearchCriteria(
    { getState }: StateContext<AllProducts>,
    {}: CreateSearchCriteria
  ) {
    const { token } = this.store.selectSnapshot(UserState);
    const { searchCriteria } = getState();
    return this.api
      .createSearchCriteria(
        token,
        searchCriteria.type === "tag"
          ? searchCriteria.tag
          : searchCriteria.criteria
      )
      .pipe(
        tap(() =>
          this.analytics.logEvent("FindSearchCriteria", { searchCriteria })
        )
      );
  }

  @Action(GetProductFeatureById)
  GetProductFeatureById(
    { patchState, getState, dispatch, setState }: StateContext<AllProducts>,
    { id }: GetProductFeatureById
  ) {
    const { features, featuresRelated } = this.store.selectSnapshot(UserState);
    const { filteredFeatures } = getState();
    let xFeature: ProductFeature;
    let found = false;
    if (filteredFeatures && filteredFeatures.length > 0) {
      xFeature = filteredFeatures.find((f) => f.id === id);
      found = !!xFeature;
      if (found) {
        dispatch(new AddClickToFeature(xFeature));
        dispatch(new SetMetaFeature(xFeature));
        dispatch(
          new ProductsFeaturesRelated(
            xFeature.Product.Tags.map((tag) => tag.id)
          )
        );
      }
    }
    if (features && features.length > 0 && !found) {
      xFeature = features.find((f) => f.id === id);
      found = !!xFeature;
      if (found) {
        dispatch(new AddClickToFeature(xFeature));
        dispatch(new SetMetaFeature(xFeature));
        dispatch(
          new ProductsFeaturesRelated(
            xFeature.Product.Tags.map((tag) => tag.id)
          )
        );
      }
    }
    if (featuresRelated && featuresRelated.length > 0 && !found) {
      xFeature = featuresRelated.find((f) => f.id === id);
      found = !!xFeature;
      if (found) {
        dispatch(new AddClickToFeature(xFeature));
        dispatch(new SetMetaFeature(xFeature));
        dispatch(
          new ProductsFeaturesRelated(
            xFeature.Product.Tags.map((tag) => tag.id)
          )
        );
      }
      patchState({ selectedFeature: xFeature });
    }
    if (!found) {
      return this.api.featureById(id).pipe(
        tap(({ data: { feature } }) => {
          this.analytics.logEvent("GetProductFeatureById", { id });
          patchState({ selectedFeature: feature });
          dispatch(new AddClickToFeature(feature));
          dispatch(
            new ProductsFeaturesRelated(
              feature.Product.Tags.map((tag) => tag.id)
            )
          );
          const firebaseUser = this.store.selectSnapshot(
            UserState.firebaseUser
          );
          if (firebaseUser && firebaseUser.TavuelUser) {
            const itemInCar = firebaseUser.TavuelUser.Cart.Detail.find(
              (d) => d.ProductFeature.id === feature.id
            );
            if (itemInCar) {
              setState(
                patch({
                  selectedFeature: patch({
                    isAdded: itemInCar.Quantity_BillDetail,
                  }),
                })
              );
            }
          }
          dispatch(new SetMetaFeature(feature));
        })
      );
    }
  }

  @Action(CleanFilteredFeatures)
  CleanFilteredFeatures(
    { patchState }: StateContext<AllProducts>,
    {}: CleanFilteredFeatures
  ) {
    patchState({ filteredFeatures: null });
  }

  @Action(AddClickToFeature)
  AddClickToFeature(
    { patchState }: StateContext<AllProducts>,
    { feature }: AddClickToFeature
  ) {
    if (feature) {
      return this.api.addClickToProductFeature(feature.id).pipe(
        tap(({ data: { updated } }) => {
          this.analytics.logEvent("AddClickToFeature", { id: feature.id });
          patchState({ selectedFeature: feature });
        })
      );
    }
  }

  @Action(SetMetaFeature)
  setMetaFeature({}: StateContext<AllProducts>, { feature }: SetMetaFeature) {
    const keywords = feature.Product.Tags
      ? feature.Product.Tags.reduce(
          (words, actual) => words.concat(`, ${actual.Value}`),
          ""
        )
      : "";
    this.title.setTitle(feature.Product.Name_Product);
    this.meta.addTags([
      { name: "keywords", content: keywords },
      { name: "description", content: feature.Product.Description_Product },
      {
        name: "author",
        content: feature.Product.Inventory.Cellar.Place.Name_Place,
      },
      { property: "og:title", content: feature.Product.Name_Product },
      {
        property: "og:description",
        content: feature.Product.Description_Product,
      },
      {
        property: "og:image",
        content: "https://muytico.com/assets/icons/icon-512x512.png",
      },
      {
        property: "og:url",
        content: `https://muytico.com/product/${feature.id}`,
      },
      { property: "og:type", content: "website" },
      { name: "twitter:title", content: feature.Product.Name_Product },
      {
        name: "twitter:description",
        content: feature.Product.Description_Product,
      },
      { name: "twitter:image", content: feature.Files[0].Route_File },
      { name: "twitter:card", content: "summary_large_image" },
    ]);
  }

  @Action(MuyTicoCategories)
  muyTicoCategories(
    { patchState }: StateContext<AllProducts>,
    {}: MuyTicoCategories
  ) {
    return this.api.muyTicoCategories().pipe(
      tap(({ data: { muyticoTags } }) => {
        this.analytics.logEvent("MuyTicoCategories");
        const newTags = muyticoTags.map((tag) => {
          const icon = categories.find((xCategory) => xCategory.id === tag.id);
          if (icon) {
            return { ...tag, icon: icon.src };
          }
          return tag;
        });
        patchState({ muyTicoCategories: newTags });
      }),
      catchError(() => {
        this.snack.open("Error al obtener los tags por padre", "OK", {
          duration: 3000,
          panelClass: ["custom-snackbar-error"],
        });
        return of(null);
      })
    );
  }

  @Action(SetProductTag)
  setProductTag(
    { patchState }: StateContext<AllProducts>,
    { tag }: SetProductTag
  ) {
    patchState({ productTag: tag, featureTag: null });
  }
  @Action(SetFeatureTags)
  setFeatureTags(
    { patchState }: StateContext<AllProducts>,
    { tags }: SetFeatureTags
  ) {
    patchState({ featureTags: tags });
  }

  @Action(SetFeatureTag)
  setFeatureTag(
    { patchState }: StateContext<AllProducts>,
    { tag }: SetFeatureTag
  ) {
    patchState({ featureTag: tag });
  }

  @Action(SetFilterPrice)
  setFilterPrice(
    { setState, dispatch }: StateContext<AllProducts>,
    { PriceOrder }: SetFilterPrice
  ) {
    setState(patch({ searchFilter: { PriceOrder } }));
    setState(patch({ filteredFeatures: null }));
    setState(patch({ loadMoreFindProducts: false }));
    dispatch(new SearchProductsFromState());
  }
}
