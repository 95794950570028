import { Pipe, PipeTransform } from '@angular/core';
import { File } from '../../interface/user';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'img'
})
export class ImgPipe implements PipeTransform {
  private defaultImg =
    'https://firebasestorage.googleapis.com/v0/b/tavuel506.appspot.com/o/utilities%2FemptyImg.png?alt=media&token=d3b629c3-3506-4bc7-aef6-21f7ac63b1e8.png';

  constructor(
    private sanitizer?: DomSanitizer,
  ) { }

  transform(files: File[], type?: string, size?: number) {
    if (files) {
      let file;
      if (!type) {
        file = files[0];
      } else {
        file = files.find(xFile => xFile.FileType.id === type);
      }
      let newRouteFile = file ? file.Route_File : this.defaultImg;
      if (size && file) {
        const d1 = new Date('2020-06-10T22:34:54.000Z');
        const d2 = new Date(file.Created_At);
        const today = new Date();
        today.setHours(6);
        newRouteFile = file.Route_File;
        if (d1 < d2 && file && size === 1) {
          if (!file.Route_File.includes('_200x200.png?') && !file.Route_File.includes('%2Fproducts%2Fthumbs%2F')) {
            newRouteFile = file.Route_File.replace('%2Fproducts%2F', '%2Fproducts%2Fthumbs%2F');
            newRouteFile = newRouteFile.replace('.png?', '_200x200.png?');
          }
        }
        if (d1 < d2 && file && size === 2) {
          if (!file.Route_File.includes('_400x400.png?') && !file.Route_File.includes('%2Fproducts%2Fthumbs%2F')) {
            newRouteFile = file.Route_File.replace('%2Fproducts%2F', '%2Fproducts%2Fthumbs%2F');
            newRouteFile = newRouteFile.replace('.png?', '_400x400.png?');
          }
        }
      }
      return file ? this.sanitizer.bypassSecurityTrustUrl(newRouteFile) : this.defaultImg;
    } else {
      return this.defaultImg;
    }
  }
}
