export class GetCartPay {
  static readonly type = '[Payment] GetCartPay]';
  constructor(public readonly id?: string) {}
}

export class CouponById {
  static readonly type = '[Payment] CouponById]';
  constructor(public readonly id?: string) {}
}

export class PayCartNative {
  static readonly type = '[Payment] PayCartNative]';
  constructor(public readonly cart: any) {}
}

export class PayCoins {
  static readonly type = '[Payment] PayCoins]';
}
