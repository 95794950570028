import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngxs/store';
import { UserState } from 'src/app/states/user.state';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Pipe({
  name: 'coinConversion'
})
export class CoinConversionPipe implements PipeTransform {

  constructor(private store?: Store) {}

  transform(value: number): Observable<string> {
    return this.store.select(UserState.defaultCoin).pipe(
      switchMap( coin => {
        if (coin) {
          const newValue = value / coin.Buy_Coin;
          return of(`${coin.Symbol_Coin}${newValue.toLocaleString('en-US', {maximumFractionDigits: 2})}`);
        }
        const { coins } = this.store.selectSnapshot(UserState);
        if (coins && coins.length > 0) {
          const newValue = value / coins[0].Buy_Coin;
          return of (`${coins[0].Symbol_Coin}${newValue.toLocaleString('en-US', {maximumFractionDigits: 2})}`);
        }
        return of(`$${value.toLocaleString('en-US', {maximumFractionDigits: 2})}`);
      })
    );
  }

}
