// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  api: "https://api.tavuel.com/dev", //"http://localhost:5000",
  apiEB: "https://api.tavuel.com/eb-dev/tavuelBilling", // 'http://localhost:4041/tavuelBilling', //
  payKey: "5M3t6r222z8b2g64AAq8rz68vJbfnEAw",
  captchaKey: "6LczOasZAAAAAMM_4xspK1WVz_JeaCBZ4YVt_Tmj",
  chatUri: "https://chatcliente.muytico.com/home",
  production: false,
  markerIcon:
    "https://firebasestorage.googleapis.com/v0/b/tavuel506.appspot.com/o/company%2Ftest.png?alt=media&token=a75e6da2-f021-4616-b26c-946efc58ed0b",
  // Develop
  firebase: {
    apiKey: "AIzaSyCBVV-BADea7PB_33t1dzx-h3KIpZsdwk0",
    authDomain: "tavuel506.firebaseapp.com",
    databaseURL: "https://tavuel506.firebaseio.com",
    projectId: "tavuel506",
    storageBucket: "tavuel506.appspot.com",
    messagingSenderId: "536950237048",
    appId: "1:536950237048:web:3c9094800aa0bab8",
    measurementId: "G-1JZKL6GVSL",
  },
  processor_id: "11550576",
  key_id: "13832390",
  billing_profile: "https://billing-profile-dev.web.app", //'http://localhost:4203',
  redirectPayment: `https://dev.muytico.com/cart`, // 'http://localhost:4200/cart' //
  nativePayment: `https://dev.muytico.com/payment/pay`, // 'http://localhost:4200/payment/pay' //
  nativeRedirect: `https://dev.muytico.com/payment/complete`, // 'http://localhost:4200/payment/pay' //
  chat_url: "https://chatcliente.muytico.com",
  defaultUserImg:
    "https://firebasestorage.googleapis.com/v0/b/tavuel506.appspot.com/o/users%2Fuser-profile.png?alt=media&token=83d5eda3-80a8-446b-8f73-f3654dcb4c7a",
  bucket_place: "dev/",
  placeId: 80,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
