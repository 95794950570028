import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Navigate } from "@ngxs/router-plugin";
import {
  Actions,
  ofActionErrored,
  ofActionSuccessful,
  Store,
} from "@ngxs/store";
import { SetLoading } from "src/app/states/actions/ui.actions";
import { AddToCart, ReduceToCart } from "src/app/states/actions/user.actions";
import { UserState } from "src/app/states/user.state";
@Component({
  selector: "app-product-result",
  templateUrl: "./product-result.component.html",
  styleUrls: ["./product-result.component.less"],
})
export class ProductResultComponent {
  @Input() selected;
  @Output() select = new EventEmitter();
  @Input() feature;
  @Input() showMessages = true;
  awaitAdd = false;
  loadedImage = false;

  constructor(
    private store: Store,
    private actions: Actions,
    private snack: MatSnackBar
  ) {}

  goToFeature = (id) => this.store.dispatch(new Navigate([`/product/${id}`]));

  addToCart = (feature, event) => {
    event.stopPropagation();
    if (!this.store.selectSnapshot(UserState.UID)) {
      this.store.dispatch(new Navigate(["/profile/login"]));
      return this.snack.open("Intenta registrarte primero", "😉", {
        duration: 1500,
      });
    } else {
      this.select.emit(feature);
      this.awaitAdd = true;
      this.handler(AddToCart, "Agregado al carrito");
      this.store.dispatch(new AddToCart(feature));
    }
  };

  handler = (action, msg?, err = "Error de conexión") => {
    let errorSubs;
    let subs;
    errorSubs = this.actions.pipe(ofActionErrored(action)).subscribe(() => {
      this.store.dispatch(new SetLoading(false, 0));
      this.awaitAdd = false;
      this.snack.open(err, "Ok", { duration: 3000 });
      errorSubs.unsubscribe();
      subs.unsubscribe();
    });
    subs = this.actions.pipe(ofActionSuccessful(action)).subscribe(() => {
      this.store.dispatch(new SetLoading(false, 0));
      this.showMessages && msg
        ? this.snack.open(msg, "😉", { duration: 2000 })
        : null;
      this.awaitAdd = false;
      errorSubs.unsubscribe();
      subs.unsubscribe();
    });
  };

  reduceToCart = (feature, event) => {
    event.stopPropagation();
    this.select.emit(feature);
    this.awaitAdd = true;
    this.handler(ReduceToCart, null);
    this.store.dispatch(new ReduceToCart(feature));
  };

  onImageLoad = () => (this.loadedImage = true);
}
