import { ProductFeature } from "src/app/interface/shop";

export class SetDrawer {
  static readonly type = "[UI] SetDrawer]";
  constructor(public readonly drawer: any) {}
}

export class InstallApp {
  static readonly type = "[UI] InstallApp]";
}

export class Loader {
  static readonly type = "[UI] Loader]";
  constructor(public readonly LoaderState: number) {}
}

export class CheckUpdated {
  static readonly type = "[UI] CheckUpdated]";
}

export class VerifyInstallPrompt {
  static readonly type = "[UI] VerifyInstallPrompt]";
}

export class ShareContent {
  static readonly type = "[UI] ShareContent]";
  constructor(
    public readonly title: string,
    public readonly text: string,
    public readonly url: string,
    public readonly feature?: ProductFeature
  ) {}
}

export class Vibrate {
  static readonly type = "[UI] Vibrate]";
  constructor(public readonly duration: number) {}
}

export class SetLoading {
  static readonly type = "[UI] SetLoading]";
  constructor(
    public readonly loading: boolean,
    public readonly yProgress?: number
  ) {}
}

export class SetProgress {
  static readonly type = "[UI] SetProgress]";
  constructor(public readonly yProgress: number) {}
}

export class SetMobile {
  static readonly type = "[UI] SetMobile]";
  constructor(public readonly mobile: boolean) {}
}

export class GetCarrousel {
  static readonly type = "[UI] GetCarrousel]";
}

export class CheckBrowser {
  static readonly type = "[UI] CheckBrowser]";
}

export class NativeApp {
  static readonly type = "[UI] NativeApp]";
}

export class DownloadXML {
  static readonly type = "[UI] DownloadXML";
  constructor(public readonly id) {}
}

export class DownloadRespXML {
  static readonly type = "[UI] DownloadRespXML";
  constructor(public readonly id) {}
}
