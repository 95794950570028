import { InMemoryCache } from "@apollo/client/core";
import { Apollo } from "apollo-angular";
import { NgModule } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpLink } from "apollo-angular/http";

@NgModule({
  declarations: [],
  imports: [],
})
export class ApolloClientsModule {
  constructor(private apollo: Apollo, private httpLink: HttpLink) {
    this.apollo.createDefault({
      link: this.httpLink.create({ uri: environment.api }),
      cache: new InMemoryCache(),
      defaultOptions: {
        watchQuery: {
          fetchPolicy: "network-only",
          errorPolicy: "ignore",
        },
        query: {
          fetchPolicy: "network-only",
          errorPolicy: "ignore",
        },
      },
    });
    this.apollo.createNamed("EB", {
      link: this.httpLink.create({ uri: environment.apiEB }),
      cache: new InMemoryCache(),
      defaultOptions: {
        watchQuery: {
          fetchPolicy: "network-only",
        },
        query: {
          fetchPolicy: "network-only",
        },
      },
    });
  }
}
